'use strict'

import React from 'react'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import { browserHistory, Link } from 'react-router'
import quantity from 'js-quantities'
import moment from 'moment'
import Snackbar from '@cimpress/react-components/lib/Snackbar'
import Alert from '@cimpress/react-components/lib/Alert'
import browserDownload from 'in-browser-download'

import ConfirmBox from './ex/ConfirmBox'
import Loading from './ex/Loading'
import auth from './auth/auth'
import permissions from './auth/permissions'
import ShippingBoxHistory from './ShippingBoxHistory'
import jsonToCsv from '../utils/jsonToCsv'

function eightOrLessCharacters(dec) {
	if (dec.toString().length > 8) {
		return dec.toString().substr(0, 7) + '...'
	} else {
		return dec
	}
}

function dimensionFormatter(cell) {
	if (!cell) {
		cell = ['0 cm', '0 cm', '0 cm']
	}
	var unit = cell[0].split(' ')[1]
	var dim1 = cell[0].split(' ')[0].toString()
	var dim2 = cell[1].split(' ')[0].toString()
	var dim3 = cell[2].split(' ')[0].toString()

	var text = eightOrLessCharacters(dim1) + ' x ' + eightOrLessCharacters(dim2) + ' x ' + eightOrLessCharacters(dim3)

	// This is WRONG as it assumes all units are the same !!
	// There is no restriction put in place for this (for now)
	text += ' ' + unit
	var title = cell
	return (
		<span data-toggle="tooltip" data-placement="bottom" title={title}>
			{text}
		</span>
	)
}

function descriptionFormatter(cell, row) {
	var link = '/ui/shippingBoxes/' + row.ShippingBoxId
	var title = cell

	if (cell && cell.length > 30) {
		var description = cell.substr(0, 30) + '...'
		title = (
			<span data-toggle="tooltip" data-placement="bottom" title={cell}>
				{description}
			</span>
		)
	}

	return (
		<div>
			<span className={row.Active ? 'text-success' : 'text-muted'}>
				<i className="fa fa-circle" aria-hidden="true" />
			</span>
			&nbsp;
			<Link to={link}>{title}</Link>
		</div>
	)
}

function weightFormatter(cell) {
	var weight = eightOrLessCharacters(cell.split(' ')[0]) + ' ' + cell.split(' ')[1]
	return (
		<span data-toggle="tooltip" data-placement="bottom" title={cell}>
			{weight}
		</span>
	)
}

function weightSorter(a, b, order) {
	var quantityA = quantity(a.Weight)
	var quantityB = quantity(b.Weight)
	if (order == 'desc') {
		return quantityA.compareTo(quantityB)
	} else {
		return quantityB.compareTo(quantityA)
	}
}

function tagsFormatter(cell, row) {
	var link = '/ui/shippingBoxes/' + row.ShippingBoxId + '#tags'
	var tags = ''
	if (cell.length > 0) {
		tags = '' + cell
	} else {
		tags = 'No tags defined'
	}
	return (
		<a href={link} className="qp-pointer">
			<i
				className="fa fa-tags tags"
				data-toggle="tooltip"
				data-placement="left"
				title={tags}
				data-original-title={tags}
			/>
		</a>
	)
}

function rowFormatter(rowData) {
	return (rowData.Active ? 'active-box' : 'inactive-box') + ' shippingboxtr' //return a class name.
}

export default class ListShippingBoxesPage extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			confirmBoxShow: false,
			shippingBoxes: null,
			displayedBoxes: null,
			location: null,
			hideBoxes: false,
			isImporting: false,
			importFile: null,
			showHistory: false,
		}
	}

	componentDidMount() {
		var selectedLocation = localStorage.getItem('selectedLocation')
		var location = null
		if (selectedLocation) {
			location = JSON.parse(selectedLocation)
		}

		if (!location) {
			browserHistory.push('/ui/fulfillmentLocations')
			return
		}

		this.serverRequests = []
		this.setState({ location: location })
		this.getShippingBoxes(location.FulfillmentLocationId)
	}

	componentWillUnmount() {
		if (this.serverRequests) {
			this.serverRequests.forEach(request => request.abort())
		}
	}

	getShippingBoxes(locationCode) {
		const token = auth.getAccessToken()
		this.serverRequests.push(
			window.$.ajax({
				method: 'GET',
				url: `${process.env.REACT_APP_SERVICE_URL}/api/v1/shippingBoxes?fulfillmentLocationId=${locationCode}`,
				timeout: 3000,
				headers: {
					Authorization: `Bearer ${token}`,
				},
				success: function (shippingBoxesData) {
					this.setState({
						shippingBoxes: shippingBoxesData,
						displayedBoxes: shippingBoxesData,
					})
				}.bind(this),
				error: function (error, _status, message) {
					if (error.status === 404) {
						this.setState({
							shippingBoxes: [],
							displayedBoxes: [],
						})
					} else {
						this.setState({
							shippingBoxes: null,
							displayedBoxes: null,
							error: message,
						})
					}
				}.bind(this),
			}),
		)
	}

	onClickAddNew(e) {
		e.preventDefault()
		browserHistory.push('/ui/shippingBoxes/new')
	}

	onClickImport(e) {
		e.preventDefault()
		this.setState({
			isImporting: true,
		})
	}

	onClickShowHistory(e) {
		e.preventDefault()
		this.setState({
			showHistory: true,
		})
	}

	async onClickExport(e) {
		e.preventDefault()
		const csv = await jsonToCsv(this.shippingBoxTable.props.data, {
			excludeKeys: ['Active', 'Tags'],
		})
		browserDownload(csv, this.defaultCsvFileName())
	}

	onSelectImportFile(e) {
		if (!e.target.value) {
			return
		}
		e.preventDefault()
		this.setState({
			importFile: e.target.files[0],
		})
		e.target.value = null
	}

	onCancelImport() {
		if (this.state.importFile) {
			this.setState({
				confirmBoxShow: true,
				confirmBoxTitle: 'Confirm import cancellation',
				confirmBoxBody: 'Unsaved changes will be lost. Do you wish to continue?',
				confirmBoxOnConfirm: this.cancelImport.bind(this),
			})
		} else {
			this.cancelImport()
		}
	}

	hideHistory() {
		this.setState({
			showHistory: false,
		})
	}

	cancelImport() {
		this.setState({
			importFile: null,
			isImporting: false,
			...this.stateToHideConfirmBox(),
		})
	}

	showDefault() {
		this.hideHistory()
		this.cancelImport()
	}

	onSaveImport() {
		this.setState({
			confirmBoxShow: true,
			confirmBoxTitle: 'Confirm import',
			confirmBoxBody: 'Imported shipping boxes will be added. Do you wish to continue?',
			confirmBoxOnConfirm: this.saveImport.bind(this),
		})
	}

	saveImport() {
		this.setState(this.stateToHideConfirmBox())
		this.importBoxes(this.state.importFile)
	}

	importBoxes(file) {
		const token = auth.getAccessToken()

		var formData = new FormData()
		formData.append('FulfillmentLocationId', this.state.location.FulfillmentLocationId)
		formData.append('ShippingBoxesImportFile', file)

		this.serverRequests.push(
			window.$.ajax({
				method: 'POST',
				url: `${process.env.REACT_APP_SERVICE_URL}/api/v1/shippingBoxes/csv`,
				processData: false,
				contentType: false,
				data: formData,
				timeOut: 10000,
				headers: {
					Authorization: `Bearer ${token}`,
				},
				success: function () {
					this.setState({
						isImporting: false,
						importFile: null,
						...this.stateToHideConfirmBox(),
					})
					this.getShippingBoxes(this.state.location.FulfillmentLocationId)
				}.bind(this),
				error: function (error) {
					this.setState({
						isImporting: false,
						importFile: null,
						...this.stateToShowSnackBar(this.formatErrorMessage(error)),
						...this.stateToHideConfirmBox(),
					})
				}.bind(this),
			}),
		)
	}

	stateToHideConfirmBox() {
		return {
			confirmBoxShow: false,
			confirmBoxOnCancel: this.onConfirmBoxCancel.bind(this),
		}
	}

	stateToShowSnackBar(message, type = 'danger') {
		return {
			snackBarShow: true,
			snackBarType: type,
			snackBarBody: message,
		}
	}

	onHideSnackbar() {
		this.setState({
			snackBarShow: false,
		})
	}

	onConfirmBoxCancel() {
		this.setState(this.stateToHideConfirmBox())
	}

	onChangeValue(key, value) {
		var update = {}
		update[key] = value
		this.setState(update)
	}

	onClickHideBoxes(e) {
		e.preventDefault()
		var hideBoxes = this.state.hideBoxes
		var boxes
		if (hideBoxes) {
			boxes = this.state.shippingBoxes
		} else {
			// Get the Active boxes
			boxes = this.state.shippingBoxes.filter(function (box) {
				if (box.Active) {
					return box
				}
			})
		}

		this.setState({
			displayedBoxes: boxes,
			hideBoxes: !hideBoxes,
		})
	}

	formatErrorMessage(error) {
		if (error.responseJSON) {
			return error.responseJSON.results.errors.map(function (each) {
				return each.code + ': ' + each.description + '(' + each.message + ')<br/><br/>'
			})
		} else if (error.responseText) {
			return error.responseText
		}
		return error
	}

	defaultCsvFileName() {
		return 'ShippingBoxes_' + this.state.location.FulfillmentLocationId + '_' + moment().format('YYYYMMDD') + '.csv'
	}

	canManage() {
		return this.state.location && permissions.canManageLocation(this.state.location.FulfillmentLocationId)
	}

	getUniqueTags() {
		const uniqueTags = {}
		this.state.shippingBoxes.forEach(box => {
			box.Tags.forEach(tag => {
				if (!uniqueTags[tag.Key]) {
					uniqueTags[tag.Key] = [{ Box: box.ShippingBoxId, Value: tag.Value }]
				} else {
					uniqueTags[tag.Key] = uniqueTags[tag.Key].concat({
						Box: box.ShippingBoxId,
						Value: tag.Value,
					})
				}
			})
		})
		return uniqueTags
	}

	getHeaders() {
		const headers = [
			<TableHeaderColumn
				key="tableColumn-shippingBoxId"
				width="60"
				dataField="ShippingBoxId"
				dataAlign="center"
				dataSort={true}
				isKey={true}
				hidden={true}
				export={true}
			>
				ID
			</TableHeaderColumn>,
			<TableHeaderColumn
				key="tableColumn-description"
				dataField="Description"
				dataAlign="left"
				dataSort={true}
				dataFormat={descriptionFormatter}
			>
				Name
			</TableHeaderColumn>,
			<TableHeaderColumn key="tableColumn-cost" width="100" dataField="Cost" dataAlign="right" dataSort={true}>
				{'Cost (' + this.state.location.Currency + ')'}
			</TableHeaderColumn>,
			<TableHeaderColumn
				key="tableColumn-weight"
				width="120"
				dataField="Weight"
				dataAlign="right"
				dataFormat={weightFormatter}
				dataSort={true}
				sortFunc={weightSorter}
			>
				Weight
			</TableHeaderColumn>,
			<TableHeaderColumn
				key="tableColumn-estimatedMaxHoldWeight"
				width="150"
				dataField="EstimatedMaxHoldWeight"
				dataAlign="right"
				dataFormat={weightFormatter}
				dataSort={true}
				sortFunc={weightSorter}
			>
				MaxHoldWeight
			</TableHeaderColumn>,
			<TableHeaderColumn
				key="tableColumn-exteriorDimensions"
				dataField="ExteriorDimensions"
				dataAlign="center"
				dataFormat={dimensionFormatter}
			>
				Exterior
			</TableHeaderColumn>,
			<TableHeaderColumn
				key="tableColumn-interiorDimensions"
				dataField="InteriorDimensions"
				dataAlign="center"
				dataFormat={dimensionFormatter}
			>
				Interior
			</TableHeaderColumn>,
			<TableHeaderColumn
				key="tableColumn-dimensionsCanIncreaseBy"
				dataField="DimensionsCanIncreaseBy"
				dataAlign="center"
				dataFormat={dimensionFormatter}
			>
				Dimensions Can Increase By
			</TableHeaderColumn>,
			<TableHeaderColumn
				key="tableColumn-tags"
				width="60"
				dataField="Tags"
				dataAlign="center"
				export={false}
				dataFormat={tagsFormatter}
			>
				Tags
			</TableHeaderColumn>,
		]
		const tagHeaders = Object.keys(this.getUniqueTags()).map((tag, index) => (
			<TableHeaderColumn dataField={tag} key={'tableColumn-' + index} hidden={true} export={true}>
				{tag}
			</TableHeaderColumn>
		))
		headers.push(...tagHeaders)
		return headers
	}

	renderImportForm() {
		return (
			<div className="qp-non-tab-content qp-margin-bottom">
				<div>
					<label className="btn btn-primary">
						<i className="fa fa-upload" />
						&nbsp;&nbsp;Select File to Import
						<input type="file" onChange={this.onSelectImportFile.bind(this)} style={{ display: 'none' }} />
					</label>
					&nbsp;&nbsp;
					<em style={{ lineHeight: '40px' }}>
						<code>{this.state.importFile ? this.state.importFile.name : 'No file selected'}</code>
					</em>
				</div>

				<p className="text-right">
					<button className="btn btn-default" onClick={this.onCancelImport.bind(this)}>
						<i className="fa fa-ban" />
						&nbsp;Cancel
					</button>
					&nbsp;&nbsp;
					<button className="btn btn-primary" disabled={!this.state.importFile} onClick={this.onSaveImport.bind(this)}>
						<i className="fa fa-floppy-o" aria-hidden="true" />
						&nbsp;Import Shipping Boxes
					</button>
				</p>
			</div>
		)
	}

	renderBoxList() {
		var shippingBoxesFormatted = this.state.displayedBoxes.map(function (box) {
			var tags = ''
			box.Tags.map(function (box) {
				if (box.Value) {
					tags += box.Key + ': ' + box.Value + '\n'
				} else {
					tags += box.Key + '\n'
				}
			})

			const formattedBox = {
				ShippingBoxId: box.ShippingBoxId,
				Active: box.Active,
				Description: box.Description,
				Cost: box.Cost,
				Weight: box.Weight,
				EstimatedMaxHoldWeight: box.EstimatedMaxHoldWeight,
				ExteriorDimensions: box.ExteriorDimensions,
				InteriorDimensions: box.InteriorDimensions,
				DimensionsCanIncreaseBy: box.DimensionsCanIncreaseBy,
				Tags: tags,
			}

			const uniqueTags = this.getUniqueTags()
			Object.keys(uniqueTags).forEach(tagKey => {
				const uniqueTag = uniqueTags[tagKey].find(t => t.Box === box.ShippingBoxId)
				if (!formattedBox[tagKey]) {
					if (uniqueTag) {
						formattedBox[tagKey] = uniqueTag.Value
					} else {
						formattedBox[tagKey] = ''
					}
				}
			})

			return formattedBox
		}, this)
		return (
			<div className="row qp-bs-table-row">
				<div className="col-md-12">
					<BootstrapTable
						ref={c => {
							this.shippingBoxTable = c
						}}
						data={shippingBoxesFormatted}
						search={true}
						hover={true}
						trClassName={rowFormatter}
					>
						{this.getHeaders()}
					</BootstrapTable>
				</div>
			</div>
		)
	}

	renderConfirmBox() {
		return (
			<ConfirmBox
				show={this.state.confirmBoxShow}
				title={this.state.confirmBoxTitle}
				body={this.state.confirmBoxBody}
				onCancel={this.state.confirmBoxOnCancel || this.onConfirmBoxCancel.bind(this)}
				onConfirm={this.state.confirmBoxOnConfirm ? this.state.confirmBoxOnConfirm : null}
			/>
		)
	}

	renderButtons() {
		return (
			<div>
				<div className="btn-group">
					<button className="btn btn-default" disabled={!this.canManage} onClick={this.onClickAddNew.bind(this)}>
						<i className="fa fa-plus" />
						&nbsp;Add
					</button>
					<button
						type="button"
						disabled={!this.canManage || !this.state.shippingBoxes || !this.state.shippingBoxes.length}
						className="btn btn-default"
						data-toggle="dropdown"
						aria-haspopup="true"
						aria-expanded="false"
					>
						<i className="fa fa-list" />
						&nbsp; Multibox tagging &nbsp;
						<span className="caret" />
					</button>
					<ul className="dropdown-menu">
						<li>
							<Link className="dropdown-item" to="/ui/shippingBoxes/advanced/multiBoxTagsAdd">
								Add tag to multiple boxes
							</Link>
						</li>
						<li>
							<Link className="dropdown-item" to="/ui/shippingBoxes/advanced/multiBoxTagsDelete">
								Delete tag from multiple boxes
							</Link>
						</li>
					</ul>
				</div>
				&nbsp;
				<div className="btn-group">
					<button className="btn btn-primary" onClick={this.onClickImport.bind(this)}>
						<i className="fa fa-upload" />
						&nbsp;Import
					</button>

					<button
						className="btn btn-primary"
						onClick={this.onClickExport.bind(this)}
						disabled={!this.state.shippingBoxes || !this.state.shippingBoxes.length}
					>
						<i className="fa fa-download" />
						&nbsp;Export
					</button>
				</div>
				&nbsp;
				<div className="btn-group">
					<button className="btn btn-default" onClick={this.onClickShowHistory.bind(this)}>
						History of all boxes
					</button>
				</div>
				<div className="pull-right">
					&nbsp;&nbsp;
					<button className="btn btn-link" onClick={this.onClickHideBoxes.bind(this)}>
						{this.state.hideBoxes ? (
							<span>
								<i className="fa fa-square-o" />
								&nbsp;Show inactive boxes
							</span>
						) : (
							<span>
								<i className="fa fa-check-square-o" />
								&nbsp;Show inactive boxes
							</span>
						)}
					</button>
				</div>
			</div>
		)
	}

	render() {
		var content = (
			<div>
				{this.renderButtons()}
				{this.state.displayedBoxes && this.state.displayedBoxes.length > 0 ? this.renderBoxList() : ''}
			</div>
		)
		if (this.state.isImporting) {
			content = this.renderImportForm()
		} else if (this.state.showHistory) {
			content = (
				<ShippingBoxHistory
					fulfillmentLocationCode={this.state.location.FulfillmentLocationId}
					error={this.state.error}
					history={this.state.history}
				/>
			)
		}

		return (
			<div>
				<ol className="breadcrumb">
					<li>{this.state.location ? this.state.location.Description : ''}</li>
					<li>
						<Link onClick={this.showDefault.bind(this)}>Shipping Boxes</Link>
					</li>
					{this.state.showHistory && <li>History</li>}
				</ol>

				{this.state.error ? <Alert message={this.state.error} /> : ''}
				{!this.state.shippingBoxes ? <Loading /> : ''}
				{this.state.shippingBoxes && this.state.shippingBoxes.length === 0 ? (
					<Alert type="info" message="No boxes have been configured." />
				) : (
					''
				)}

				<Snackbar
					show={this.state.snackBarShow || false}
					onHideSnackbar={this.onHideSnackbar.bind(this)}
					bsStyle={this.state.snackBarType || 'danger'}
					delay={8000}
				>
					{this.state.snackBarBody}
				</Snackbar>

				<ConfirmBox
					show={this.state.confirmBoxShow}
					title={this.state.confirmBoxTitle}
					body={this.state.confirmBoxBody}
					onCancel={this.state.confirmBoxOnCancel || this.onConfirmBoxCancel.bind(this)}
					onConfirm={this.state.confirmBoxOnConfirm ? this.state.confirmBoxOnConfirm : null}
				/>

				{content}
			</div>
		)
	}
}
