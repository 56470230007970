'use strict'

import React from 'react'
import PropTypes from 'prop-types'
import Alert from '@cimpress/react-components/lib/Alert'
import Loading from './ex/Loading'
import NavLink from './ex/NavLink'
import Header from '@cimpress-technology/react-platform-header/lib/Header'
import auth from './auth/auth'
import permissions from './auth/permissions'
import MailUsLink from './ex/MailUsLink'

export default class ShippingBoxApp extends React.Component {
	constructor(props) {
		super(props)

		this.state = {}
	}

	componentDidMount() {
		// If authenticated, get the locations
		if (auth.isLoggedIn()) {
			this.retrievePermissions()
		} else if (
			!this.props.location ||
			(this.props.location.query.logout === undefined && this.props.location.query.login === undefined)
		) {
			// only login automatically if neither logout nor (failed) login has just been performed
			this.onLoginClicked()
		}
	}

	retrievePermissions() {
		const token = auth.getAccessToken()
		const profileData = auth.getProfile()
		if (!profileData || !profileData.sub) {
			this.setState({
				error: 'Authorization failed: Unable to retrieve user information (auth0sub).',
			})
		} else {
			this.setState({ authorizing: true })
			permissions.retrieve(token, profileData.sub, error => {
				let errorObject = error
				if (error) {
					const errorTitle = 'Authorization failed: Unable to retrieve user permissions'
					const stackTrace = printStackTrace({ e: error }) // eslint-disable-line
					const prettyStacktrace = stackTrace.join('\n')
					const prettyResponse = JSON.stringify(error, null, 2)
					const prettyMessage = prettyStacktrace + '\n\n' + prettyResponse
					errorObject = (
						<div>
							<h5>{errorTitle}</h5>
							{'ECONNABORTED' === error.code ? (
								<p>Connection to Access Management Service was aborted due to timeout.</p>
							) : null}
							<p>Error message:</p>
							<pre dangerouslySetInnerHTML={{ __html: stackTrace[0] }} />
							<p>
								You can try again or should the problem persist please contact{' '}
								<MailUsLink subject={errorTitle} body={prettyStacktrace} />.
							</p>
							<p>
								<button
									className="btn btn-default"
									onClick={() => {
										this.setState({
											error: undefined,
										})
										this.retrievePermissions()
									}}
								>
									Try again
								</button>
							</p>
						</div>
					)
					// send error information to back end
					window.onerror(errorTitle + '\n\n' + prettyMessage, 'ShippingBoxApp.jsx', 60, 0, error)
				}
				this.setState({
					authorizing: false,
					error: errorObject,
				})
			})
		}
	}

	getNavigationLinks() {
		return [
			{
				title: 'Fulfillment Locations',
				link: '/ui/fulfillmentLocations',
			},
			{
				title: 'Boxes',
				link: '/ui/shippingBoxes',
			},
			{
				title: 'Eligibility Rules',
				link: '/ui/rules',
			},
			{
				title: 'Help',
				link: '/ui/help',
			},
			{
				title: 'Contact Us',
				link: '/ui/contactUs',
			},
		]
	}

	onLoginClicked() {
		localStorage.clear()
		permissions.clear()
		this.setState({ authenticating: true })
		auth
			.ensureAuthentication(window.location.pathname + '?login') // login query parameter indicates that a login has been tried
			.then(authenticated => {
				if (authenticated) {
					this.setState({
						authenticating: false,
					})
					this.retrievePermissions()
				}
			})
			.catch(error => {
				this.setState({
					authenticating: false,
					error: error,
				})
			})
	}

	onLogoutClick() {
		localStorage.clear()
		permissions.clear()
		this.setState({
			authenticating: false,
			authorizing: false,
			error: undefined,
		})
		auth.logout('/ui/contactUs?logout') // logout query param indicates that a logout has been done (show contact us page)
	}

	render() {
		let content = ''
		let children = ''

		if (this.state.error) {
			let errorMessage =
				typeof this.state.error === 'string' || React.isValidElement(this.state.error)
					? this.state.error
					: JSON.stringify(this.state.error, null, 2)
			content = <Alert type="danger" message={errorMessage} />
		} else if (!auth.isLoggedIn()) {
			if (this.state.authenticating) {
				content = <Loading message={'Authenticating ...'} />
			} else {
				content = (
					<Alert
						type="info"
						message="Authentication required! If you are not logged in automatically, please click on the Login button in the top right corner."
					/>
				)
			}
		} else if (this.state.authorizing) {
			content = <Loading message={'Authorizing ...'} />
		} else {
			children = this.props.children
		}
		// special case; show the contactUs page also when logged out
		if (window.location.pathname.endsWith('/contactUs')) {
			children = this.props.children
		}

		return (
			<div>
				<Header
					appTitle="Shipping Box Management"
					appLinks={[
						<ul className="nav nav-tabs" key="hello">
							{this.getNavigationLinks().map(function (link) {
								return (
									<NavLink to={link.link} key={link.link}>
										{link.title}
									</NavLink>
								)
							})}
						</ul>,
					]}
					profile={auth.getProfile()}
					isLoggedIn={auth.isLoggedIn()}
					accessToken={auth.getAccessToken()}
					onLogInClicked={this.onLoginClicked.bind(this)}
					onLogOutClicked={this.onLogoutClick.bind(this)}
				/>
				<div className="container-fluid qp-body-container" role="main">
					{content}
					{children}
				</div>
			</div>
		)
	}
}

ShippingBoxApp.propTypes = {
	children: PropTypes.object,
	location: PropTypes.object,
}
