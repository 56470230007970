'use strict'

import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'

class NavLink extends React.Component {
	constructor(props) {
		super(props)
	}

	__getContext() {
		if (this.context && this.context.router) {
			return this.context
		}

		// This is used for UT
		return {
			router: {
				isActive: function () {
					return true
				},
			},
		}
	}

	render() {
		var navClassName = this.props.navClassName ? this.props.navClassName : ''
		var navDisabled = this.props.navDisabled
		var wrapperElement = this.props.wrapperElement ? this.props.wrapperElement : 'li'
		var className = navClassName + (this.__getContext().router.isActive(this.props.to, false) ? ' active' : '')

		if (wrapperElement == 'div') {
			if (navDisabled) {
				return (
					<div className={className + ' disabled'}>
						<Link to={this.props.to} onClick={e => e.preventDefault()} style={{ cursor: 'no-drop' }}>
							{this.props.children}
						</Link>
						{this.props.navSibling ? this.props.navSibling : ''}
					</div>
				)
			}
			return (
				<div className={className}>
					<Link to={this.props.to} disabled={this.props.navDisabled}>
						{this.props.children}
					</Link>
					{this.props.navSibling ? this.props.navSibling : ''}
				</div>
			)
		}

		if (navDisabled) {
			return (
				<li className={className + ' disabled'}>
					<Link to={this.props.to} onClick={e => e.preventDefault()} style={{ cursor: 'no-drop' }}>
						{this.props.children}
					</Link>
					{this.props.navSibling ? this.props.navSibling : ''}
				</li>
			)
		}

		return (
			<li className={className}>
				<Link to={this.props.to}>{this.props.children}</Link>
				{this.props.navSibling ? this.props.navSibling : ''}
			</li>
		)
	}
}

NavLink.contextTypes = {
	router: PropTypes.object.isRequired,
}

NavLink.propTypes = {
	to: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired,
	navSibling: PropTypes.string,
	navDisabled: PropTypes.bool,
	navClassName: PropTypes.string,
	wrapperElement: PropTypes.string,
}

export default NavLink
