'use strict'

import { centralizedAuth } from '@cimpress/simple-auth-wrapper'

const clientId = 'p5jaySbSgOM55aAo0qPrIiUSFRyHBH3J'
const auth = new centralizedAuth({
	clientID: clientId,
	redirectRoute: '/',
})

export default auth
