const { json2csv } = require('json-2-csv')

module.exports = (data, options) =>
	new Promise((resolve, reject) => {
		if (options) {
			options.expandArrayObjects = true
		} else {
			options = {
				expandArrayObjects: true,
			}
		}
		json2csv(
			data,
			(err, csv) => {
				if (err) {
					reject(err)
				}

				resolve(csv)
			},
			options,
		)
	})
