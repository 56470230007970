'use strict'

import React from 'react'
import PropTypes from 'prop-types'
import TextField from '@cimpress/react-components/lib/TextField'

export default class TextFieldWithValidation extends React.Component {
	constructor(props) {
		super(props)
	}

	handleValueChange(e) {
		this.props.handleValueChange(e.target.value)
	}

	render() {
		var disabled = !!this.props.disabled

		var valid = true
		var helpMessage = <i className="text-muted">{this.props.helpMessage}</i>

		if (this.props.validator && this.props.value !== null && this.props.value !== undefined) {
			var errors = this.props.validator(this.props.value)
			valid = errors.length === 0
		}

		if (this.props.crossFieldError === true) {
			valid = false
		}

		if (!valid) {
			helpMessage = (
				<div className="text-danger">{this.props.errorMessage || (errors && errors[0]) || this.props.helpMessage}</div>
			)
		}

		return (
			<div>
				<TextField
					value={this.props.value}
					placeholder={this.props.placeholder}
					label={this.props.label}
					required={!disabled && this.props.required}
					disabled={disabled}
					onChange={this.handleValueChange.bind(this)}
					onBlur={this.handleValueChange.bind(this)}
					bsStyle={valid == null || disabled ? undefined : valid ? 'success' : 'error'}
				/>
				<div>
					<div className="qp-text-field-message">{helpMessage}</div>
				</div>
			</div>
		)
	}
}

TextFieldWithValidation.propTypes = {
	disabled: PropTypes.bool,
	value: PropTypes.string.isRequired,
	required: PropTypes.bool,
	placeholder: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	helpMessage: PropTypes.string,
	errorMessage: PropTypes.string,
	validator: PropTypes.func.isRequired,
	handleValueChange: PropTypes.func.isRequired,
	crossFieldError: PropTypes.bool,
}

TextFieldWithValidation.defaultProps = {
	required: true,
}
