'use strict'

import React from 'react'
import { browserHistory } from 'react-router'
import Utils from '../../utils/Utils'
import RuleItem from '../RuleItem'

export default class HelpSectionEligibility extends React.Component {
	constructor() {
		super()

		this.state = {}
	}

	componentDidMount() {
		var selectedLocation = localStorage.getItem('selectedLocation')
		var location = null
		if (selectedLocation) {
			location = JSON.parse(selectedLocation)
		}

		if (!location) {
			browserHistory.push('/ui/fulfillmentLocations')
			return
		}

		this.setState({ location: location })
	}

	renderDescriptionList(caption, items) {
		return (
			<div>
				<p>{caption}</p>
				<dl className="dl-horizontal">
					{items.map(function (item) {
						return [
							<dt key={item.Name}>
								<code>{item.Name}</code>
							</dt>,
							<dd key={item.Description}>{item.Description}</dd>,
						]
					})}
				</dl>
			</div>
		)
	}

	renderSamples(items) {
		return items.map(function (item, i) {
			return (
				<pre key={i}>
					<code>{item}</code>
				</pre>
			)
		})
	}

	render() {
		var isViperLocation = this.state.location && Utils.isViperLocation(this.state.location.FulfillmentLocationId)
		var identifiers = [
			{
				Name: 'OrderBrand',
				Description: 'Brand information from the Viper brand table.',
				Samples: ['VistaPrint', 'OfficeMax', 'Pixart'],
			},
			{
				Name: 'WorkflowType',
				Description: 'Workflow information from the viper workflow table.',
				Samples: ['Offset', 'Digital', 'Stamp'],
			},
			{
				Name: 'BinningType',
				Description: 'Binning information from the viper binning table. e.g., "Bin", "DirectShip"',
				Samples: ['Bin', 'DirectShip'],
			},
		]

		var completeSamples = [
			'OrderBrand == "FedEx"',
			'BinningType != "Binning"',
			'(WorkflowType == "Embroidery" || WorkflowType == "Mugs") && (BinningType != "DirectShip")',
		]

		if (!isViperLocation) {
			identifiers = [
				{
					Name: 'SkuCode',
					Description: 'SKU (Stock Keeping Unit) code of the item.',
					Samples: ['Pickachu100', 'Raichu200'],
				},
				{
					Name: 'Quantity',
					Description: 'The quantity of the given SKU.',
					Samples: ['100', '200'],
				},
			]

			completeSamples = [
				'SkuCode == "Pikachu100"',
				'Quantity >= "1000"',
				'SkuCode == "Pikachu200" && Quantity >= "100" && Quantity <= "200"',
			]
		}

		// default operands
		var operands = [
			{
				Name: '&&',
				Description: (
					<span>
						represents 'and'. This operator combines two conditions together, making the condition as a whole valid only
						when both conditions are valid. For instance, the following is a valid condition:
						<br />
						<code>
							{identifiers[0].Name} == "{identifiers[0].Samples[0]}" && {identifiers[0].Name}
							== "{identifiers[0].Samples[1]}"
						</code>
						.
					</span>
				),
			},
			{
				Name: '||',
				Description: (
					<span>
						represents 'or'. This operator combines two conditions together, making the condition as a whole valid if
						either conditions are valid. For instance, the following is a valid condition:
						<br />
						<code>
							{identifiers[0].Name} == "{identifiers[0].Samples[0]}" || {identifiers[0].Name}
							== "{identifiers[0].Samples[1]}"
						</code>
						.
					</span>
				),
			},
			{
				Name: '==',
				Description: "represents 'equal to'.",
			},
			{
				Name: '!=',
				Description: "represents 'NOT equal to'.",
			},
		]

		// extended operands
		if (!isViperLocation) {
			operands.push({
				Name: '<',
				Description: "represents 'less than'.",
			})
			operands.push({
				Name: '<=',
				Description: "represents 'less than or equal to'.",
			})
			operands.push({
				Name: '>',
				Description: "represents 'greater than'.",
			})
			operands.push({
				Name: '>=',
				Description: "represents 'greater than or equal to'.",
			})
		}

		var validIdentifierNames = identifiers
			.map(function (identifier) {
				return identifier.Name
			})
			.join(', ')

		var validIdentifierSamples = identifiers
			.map(function (identifier) {
				return '"' + identifier.Samples.join('", "') + '"'
			})
			.join(', ')

		return (
			<div>
				<h3>Eligibility Rules</h3>

				<p>
					Eligibility rules can be configured per fulfillment location. The purpose of these rules is to make different
					sets of shipping boxes available for algorithmic selection based on specific order properties (e.g.{' '}
					{validIdentifierNames}). An eligibility rule has two parts: the condition, and the tag.
				</p>
				<br />

				<h5>Condition</h5>
				<br />
				<p>
					The condition is a logical expression which defines when the rule should be applied. At the minimum, it should
					contain at least one identifier, one operator and a value. For reference, the following are examples of valid
					conditions:
				</p>
				{this.renderSamples(completeSamples)}

				<p>
					Note that the value of the identifier (e.g., {validIdentifierNames}) should be surrounded by double quotes
					(e.g., {validIdentifierSamples}).
				</p>

				<p>
					<h6>Identifier</h6>
				</p>
				{this.renderDescriptionList('Valid identifiers include:', identifiers)}

				<p>
					<h6>Operator</h6>
				</p>
				{this.renderDescriptionList('Valid operators include:', operands)}

				<br />

				<h5>Tag</h5>
				<br />
				<p>
					The 'Tag to Filter Shipping Boxes' field refers to the tag which shipping boxes should be filtered by, if the
					preceding condition is met. For instance, in the following eligibility rule, shipping boxes tagged with{' '}
					<code>SimpleBoxGroup</code> should be selected if the
					<code>{identifiers[0].Name}</code> == <code>"{identifiers[0].Samples[0]}"</code>.
				</p>
				<RuleItem
					rule={{
						Condition: identifiers[0].Name + ' == "' + identifiers[0].Samples[0] + '"',
						Tag: 'SimpleBoxGroup',
						Index: 2,
					}}
					numberOfBoxesWithTheSameTag="5"
					numberOfActiveBoxesWithTheSameTag="4"
				/>
				<br />
				<p>
					Note that <span className="text-primary">[5 boxes, 4 active]</span> is a feature of the UI which lets users
					know the number of shipping boxes which have that particular tag and how many of them are marked as active.
				</p>
				<br />

				<h5>Order of Eligibility Rules</h5>
				<br />
				<p>
					Only one eligibility rule may be considered at any time. Therefore, when there are multiple eligibility rules
					for a fulfillment location, the eligibility rule at the top of the list has a higher priority than the
					eligibility rules following it. In the case where the first eligibility rule evaluates to false, the next
					eligibility rule will be considered, and so on.
				</p>
			</div>
		)
	}
}
