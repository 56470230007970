'use strict'

import React from 'react'
import PropTypes from 'prop-types'
import HrefLink from './HrefLink'
import * as globals from '../../utils/globals'

class MailUsLink extends React.Component {
	render() {
		let body = this.props.body ? `&body=${encodeURIComponent(this.props.body)}` : ''
		return (
			<HrefLink
				to={`mailto:${encodeURIComponent(
					globals.SUPPORT_EMAIL,
				)}?subject=${encodeURIComponent(this.props.subject)}${body}`}
				title="BoxMan Support"
			/>
		)
	}
}

MailUsLink.propTypes = {
	subject: PropTypes.string.isRequired,
	body: PropTypes.string,
}

export default MailUsLink
