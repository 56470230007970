'use strict'

// checks whether "value" is non-empty non-whitespace string
module.exports.validateNonEmptyString = validateNonEmptyString
function validateNonEmptyString(value, name) {
	return value && value.trim()
		? []
		: ['Invalid value for parameter "' + name + '". Expected a non-empty string, but received: "' + value + '"']
}

// checks whether "value" is a non-negative number
module.exports.validateNonNegativeNumber = validateNonNegativeNumber
function validateNonNegativeNumber(value, name) {
	return /^((([1-9][0-9]*)|(0))([.][0-9]+)?)$/.test(value)
		? []
		: ['Invalid value for parameter "' + name + '". Expected a non-negative number, but received: "' + value + '"']
}

module.exports.validateLength = validateLength
function validateLength(value, name) {
	return validateUnit(value, name, ['m', 'cm', 'mm', 'in'], true)
}

module.exports.validateWeight = validateWeight
function validateWeight(value, name) {
	return validateUnit(value, name, ['kg', 'g', 'lb', 'oz'])
}

module.exports.validatePositiveNumber = validatePositiveNumber
function validatePositiveNumber(value, name) {
	if (parseFloat(value) === 0) {
		return ['Invalid value for parameter "' + name + '". Expected a positive number, but received: "' + value + '"']
	}
	return /^((([1-9][0-9]*)|(0))([.][0-9]+)?)$/.test(value)
		? []
		: ['Invalid value for parameter "' + name + '". Expected a positive number, but received: "' + value + '"']
}

module.exports.validateFlexibleLength = validateFlexibleLength
function validateFlexibleLength(value, name) {
	return validateUnit(value, name, ['m', 'cm', 'mm', 'in'])
}

module.exports.stringifyErrors = stringifyErrors
function stringifyErrors(validationErrors) {
	return 'Request validation failed: ' + validationErrors.join(', \n')
}

// Internal function

function validateUnit(value, name, units, mustBePositive) {
	let unitPart
	let numericPart
	if (/^([0-9]*.?[0-9]* ?[a-z]{2})$/.test(value)) {
		// two-letter unit (kg, cm)
		numericPart = value.slice(0, -2)
		numericPart = numericPart.toString().trim()
		unitPart = value.slice(-2)
	} else if (/^[0-9]*.?[0-9]* ?[a-z]$/.test(value)) {
		// one-letter unit (g, m)
		numericPart = value.slice(0, -1)
		numericPart = numericPart.toString().trim()
		unitPart = value.slice(-1)
	} else {
		return [`Invalid unit format in "${name}", expected "value unit", but received: "${value}"`]
	}

	const validationErrors = mustBePositive
		? validatePositiveNumber(numericPart, name)
		: validateNonNegativeNumber(numericPart, name)

	if (validationErrors.length > 0 || units.indexOf(unitPart) == -1) {
		return [`Invalid unit format in "${name}", expected "value unit", but received: "${value}"`]
	}

	return []
}
