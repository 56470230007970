'use strict'

import Permissions from '../../qp-components/simple-coam-auth'

const permissions = new Permissions({
	dataStore: {
		get: key => JSON.parse(localStorage.getItem(key) || null),
		set: (key, value) => localStorage.setItem(key, JSON.stringify(value)),
		remove: key => localStorage.removeItem(key),
	},
	requiredResourceTypes: ['fulfillment-locations'],
	requiredPermissions: ['read:logistics-configuration-data', 'manage:logistics-configuration-data'],
	requestTimeout: 5000,
})

function listToObj(array) {
	const result = {}
	array.forEach(item => (result[item] = true))
	return result
}

permissions.canReadLocations = () =>
	listToObj(permissions.list('fulfillment-locations', 'read:logistics-configuration-data') || [])
permissions.canManageLocations = () =>
	listToObj(permissions.list('fulfillment-locations', 'manage:logistics-configuration-data') || [])
permissions.canManageLocation = locationId =>
	permissions.check('fulfillment-locations', 'manage:logistics-configuration-data', locationId)

export default permissions
