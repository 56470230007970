'use strict'

import React from 'react'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import Alert from '@cimpress/react-components/lib/Alert'
import Loading from './ex/Loading'
import auth from './auth/auth'

export default class ShippingBoxHistory extends React.Component {
	constructor(props) {
		super(props)
		this.analyzeProps(props, false)
	}

	componentWillReceiveProps(props) {
		this.analyzeProps(props, true)
	}

	analyzeProps(props, useSetState) {
		var newState = {
			history: null,
			error: null,
			shippingBoxId: props.shippingBoxId,
			fulfillmentLocationCode: props.fulfillmentLocationCode,
		}

		if (useSetState) {
			this.setState(newState)
		} else {
			this.state = newState
		}
	}

	componentDidMount() {
		if (this.state.shippingBoxId) {
			this.getShippingBoxHistory(this.state.shippingBoxId)
		} else if (this.state.fulfillmentLocationCode) {
			this.getFulfillmentLocationHistory(this.state.fulfillmentLocationCode)
		}
	}

	componentWillUnmount() {
		if (this.serverRequest) {
			this.serverRequest.abort()
		}
	}

	getShippingBoxHistory(shippingBoxId) {
		const token = auth.getAccessToken()
		this.serverRequest = window.$.ajax({
			method: 'GET',
			url: `${process.env.REACT_APP_SERVICE_URL}/api/v1/shippingBoxes/${shippingBoxId}/history`,
			timeout: 3000,

			headers: {
				Authorization: `Bearer ${token}`,
			},

			success: function (shippingBoxHistoryData) {
				this.setState({
					history: shippingBoxHistoryData,
				})
			}.bind(this),

			error: function (_error, _status, message) {
				this.setState({
					history: null,
					error: message,
				})
			}.bind(this),
		})
	}

	getFulfillmentLocationHistory(fulfillmentLocationCode) {
		const token = auth.getAccessToken()
		this.serverRequest = window.$.ajax({
			method: 'GET',
			url: `${process.env.REACT_APP_SERVICE_URL}/api/v1/shippingBoxes/${fulfillmentLocationCode}/locationHistory`,
			timeout: 3000,

			headers: {
				Authorization: `Bearer ${token}`,
			},

			success: function (shippingBoxHistoryData) {
				this.setState({
					history: shippingBoxHistoryData,
				})
			}.bind(this),

			error: function (_error, _status, message) {
				this.setState({
					history: null,
					error: message,
				})
			}.bind(this),
		})
	}

	render() {
		if (this.state.shippingBoxId == -1 || !this.state) {
			return (
				<div>
					<br />
					<Alert type="info" message="History information unavailable" />
				</div>
			)
		}

		if (this.state.error) {
			return (
				<div>
					<br />
					<Alert message={this.state.error} />
				</div>
			)
		}

		function dateTimeFormatter(cell) {
			if (cell.toString().length > 19) {
				return cell.toString().substr(0, 19).replace('T', ' ')
			} else {
				return cell
			}
		}

		function diffFormatter(cell) {
			var parts = cell.split(';')
			var diff = parts.join('<br/>')
			return diff
		}

		if (this.state.history) {
			return (
				<div className="row">
					<div className="col-md-12">
						<BootstrapTable data={this.state.history} search={true} hover={true} trClassName="active-box">
							<TableHeaderColumn width="200" dataField="Id" dataAlign="center" dataSort={true} isKey={true}>
								BOX Name
							</TableHeaderColumn>
							<TableHeaderColumn
								width="150"
								dataField="DateTime"
								columnClassName="qp-word-wrap"
								dataAlign="left"
								dataFormat={dateTimeFormatter}
								dataSort={true}
							>
								DateTime
							</TableHeaderColumn>
							<TableHeaderColumn width="250" dataField="Author" dataSort={false}>
								Author
							</TableHeaderColumn>
							<TableHeaderColumn dataField="Description" columnClassName="qp-word-wrap" dataFormat={diffFormatter}>
								Change
							</TableHeaderColumn>
							<TableHeaderColumn dataField="Reason" columnClassName="qp-word-wrap" dataSort={false}>
								Reason
							</TableHeaderColumn>
						</BootstrapTable>
					</div>
				</div>
			)
		} else {
			return <Loading />
		}
	}
}
