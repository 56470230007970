'use strict'

import React from 'react'
import PropTypes from 'prop-types'
import NavLink from './ex/NavLink'

export default class HelpPage extends React.Component {
	render() {
		return (
			<div>
				<div className="col-md-2">
					<div className="card">
						<div className="card-block">
							<h5>Help</h5>
							<ul className="nav nav-stacked nav-pills">
								<NavLink to="/ui/help/boxProperties">Shipping Box</NavLink>
								<NavLink to="/ui/help/boxTags">Tags</NavLink>
								<NavLink to="/ui/help/eligibility">Eligibility</NavLink>
								<NavLink to="/ui/help/access">Access</NavLink>
								<NavLink to="/ui/help/faq">FAQ</NavLink>
							</ul>
						</div>
					</div>
				</div>
				<div className="col-md-10">
					<div className="card">
						<div className="card-block">{this.props.children}</div>
					</div>
				</div>
			</div>
		)
	}
}

HelpPage.propTypes = {
	children: PropTypes.object,
}
