'use strict'

const fulfillmentLocationsClient = require('cimpress-fulfillment-location')
const fulfillersClient = require('cimpress-fulfiller-identity')
const fulfillmentLocationsService = new fulfillmentLocationsClient.FulfillmentLocationClient({
	cacheConfig: { stdTTL: 4 * 60 * 60, checkperiod: 5 * 60 },
	timeout: 5000,
})

function loadLocationsData(authorizationToken) {
	let authorization = `Bearer ${authorizationToken}`

	const fulfillersService = new fulfillersClient(authorization)

	let fl = fulfillmentLocationsService.getLocations(authorization)
	let fi = fulfillersService.getFulfillers()

	return Promise.all([fl, fi])
		.then(results => {
			return Promise.resolve(transformLocationsData(results[0], results[1]))
		})
		.catch(err => {
			return Promise.reject(`Unexpected response while getting locations: ${JSON.stringify(err)}`)
		})
}

function transformLocationsData(fulfillmentLocationsData, fulfillersData) {
	let locations = []
	const fulfillers = fulfillersData || []
	fulfillmentLocationsData.forEach(location => {
		let fulfiller = fulfillers.find(fi => fi.fulfillerId === location.fulfillerId) || {}
		locations.push({
			FulfillerId: location.fulfillerId,
			InternalFulfillerId: location.internalFulfillerId,
			FulfillerName: fulfiller.name || 'Unknown Fulfiller',

			FulfillmentLocationId: location.fulfillmentLocationId,
			InternalFulfillmentLocationId: location.internalFulfillmentLocationId,
			FulfillmentLocationName: location.name,
		})
	})
	return locations.sort(function (a, b) {
		return a.FulfillmentLocationName.localeCompare(b.FulfillmentLocationName)
	})
}

module.exports.loadLocationsData = loadLocationsData
