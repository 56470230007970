import React from 'react'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import MailUsLink from '../ex/MailUsLink'
import HrefLink from '../ex/HrefLink'

export default class HelpSectionShippingBoxTags extends React.Component {
	render() {
		var viperTagsPlanning = [
			{
				Key: 'viper:ShippingCategory',
				Desc:
					'This tag defines the "Viper shipping category" of a box. Viper uses this category in numerous places to allow or restrict ' +
					'shipping boxes from being selected. If a planned shipment fits in multiple shipping boxes, the shipping category is ' +
					'also used to "optimize" the selection.' +
					'<br/><strong>Format</strong>: One of "Parcel", "Envelope" or "Flat"' +
					'<br/><strong>Default</strong>: Parcel',
			},
			{
				Key: 'viper:UsableVolumePercentage',
				Desc:
					'Defines how much of the interior of a shipping box is "available". Normally, the shipping box volume is calculated by multiplying the 3 interior dimensions. ' +
					'Setting this value to non-100% value results in using only the respective percentage of the volume. ' +
					'Example: For a box with interior dimensions [ 2 cm, 3 cm, 5 cm] the volume is 2 x 3 x 5 = 30 cm<sup>3</sup>. ' +
					'If we set the value of this tag to 40, the actual volume that Viper Shipping Planning will consider will be 30 * 40% = 12 cm<sup>3</sup>' +
					'<br/><strong>Format</strong>: A number between 0 (zero) and 100 (a hundred)' +
					'<br/><strong>Default</strong>: 100',
			},
			{
				Key: 'viper:Precedence',
				Desc:
					'This tag refers to the precedence that this shipping box takes over other boxes when applying box replacement rules. ' +
					'A lower number denotes a higher precedence; thus, a lower-numbered box will replace a higher-numbered box if two conflicting box replacement rules ' +
					'are defined on the pack station where the evaluation is being done.' +
					'<br/><strong>Format</strong>: A whole number' +
					'<br/><strong>Default</strong>: 1',
			},
		]

		var viperTagsSorter = [
			{
				Key: 'viper:ClosingDeviceMinWeight',
				Desc:
					'Property used for BMR Sorter.' +
					'<br/><strong>Format</strong>: "[number] [unit]"' +
					'<br/><strong>Example</strong>: "1 lb" or "12.34 kg"',
			},
			{
				Key: 'viper:SingleItemWeight',
				Desc:
					'Property used for BMR Sorter.' +
					'<br/><strong>Format</strong>: "[number] [unit]"' +
					'<br/><strong>Example</strong>: "1 lb" or "12.34 kg"',
			},
			{
				Key: 'viper:MinAccumWeight',
				Desc:
					'Property used for BMR Sorter.' +
					'<br/><strong>Format</strong>: "[number] [unit]"' +
					'<br/><strong>Example</strong>: "1 lb" or "12.34 kg"',
			},
			{
				Key: 'viper:MaxAccumWeight',
				Desc:
					'Property used for BMR Sorter.' +
					'<br/><strong>Format</strong>: "[number] [unit]"' +
					'<br/><strong>Example</strong>: "1 lb" or "12.34 kg"',
			},
		]

		var viperTagsOthers = [
			{
				Key: 'viper:ContainerMaterialID',
				Desc: (
					<span>
						Please avoid adding this tag unless absolutely necessary. If you think you need to add or edit the value for
						this tag, please contact
						<MailUsLink subject={''} />
					</span>
				),
			},
			{
				Key: 'sap:Number',
				Desc: (
					<span>
						The <HrefLink to="https://corewiki.cimpress.net/wiki/SAP" title={'SAP'} /> number associated with this box.
					</span>
				),
			},
			{
				Key: 'viper:Tape',
				Desc:
					'The tape that should be used at the pack station to seal this shipping box. The value in this column will be displayed on ' +
					'the screen at a pack station when the packer is instructed to use a given shipping box.',
			},
		]

		var viperTagsMigration = [
			{
				Key: 'viper:ContainerNbr',
				Desc: (
					<span>
						<strong>This tag will only be supported during the migration from containers to shipping boxes</strong>
						<br />
						Please avoid modifying (or setting) this tag unless absolutely necessary. If you think you need to add or
						edit the value for this tag, please contact <MailUsLink subject={''} />. This tag is used to map "Viper
						Containers" to "ShippingBoxes" during the migration phase.
					</span>
				),
			},
		]

		function allowHtmlFormatter(cell) {
			return cell
		}

		return (
			<div>
				<h3>Introduction</h3>
				<p className="wall">
					Tags are a mechanism to define information that is not considered core to a shipping box, but instead may be
					used by a separate system to associate information that that system needs with a particular shipping box. A
					tag is made up of a key, which can be any string, and a value, which can also be any string. The value may be
					empty.
				</p>

				<h3>Viper specific tags</h3>
				<p>
					Viper specific tags are tags that, if present, will be used by Viper to control certain behavior, as described
					below. If absent, they will default to a reasonable value, also shown below.
				</p>

				<br />
				<h5>Shipping Planning related tags</h5>
				<BootstrapTable data={viperTagsPlanning}>
					<TableHeaderColumn dataField="Key" width="250px" isKey={true}>
						Key
					</TableHeaderColumn>
					<TableHeaderColumn dataField="Desc" dataFormat={allowHtmlFormatter} columnClassName="qp-word-wrap">
						Description
					</TableHeaderColumn>
				</BootstrapTable>

				<br />
				<h5>BMR Sorter</h5>
				<BootstrapTable data={viperTagsSorter}>
					<TableHeaderColumn dataField="Key" width="250px" isKey={true}>
						Key
					</TableHeaderColumn>
					<TableHeaderColumn dataField="Desc" dataFormat={allowHtmlFormatter} columnClassName="qp-word-wrap">
						Description
					</TableHeaderColumn>
				</BootstrapTable>

				<br />
				<h5>Others</h5>
				<BootstrapTable data={viperTagsOthers}>
					<TableHeaderColumn dataField="Key" width="250px" isKey={true}>
						Key
					</TableHeaderColumn>
					<TableHeaderColumn dataField="Desc" dataFormat={allowHtmlFormatter} columnClassName="qp-word-wrap">
						Description
					</TableHeaderColumn>
				</BootstrapTable>

				<br />
				<h5>Migration</h5>
				<BootstrapTable data={viperTagsMigration}>
					<TableHeaderColumn dataField="Key" width="250px" isKey={true}>
						Key
					</TableHeaderColumn>
					<TableHeaderColumn dataField="Desc" dataFormat={allowHtmlFormatter} columnClassName="qp-word-wrap">
						Description
					</TableHeaderColumn>
				</BootstrapTable>
			</div>
		)
	}
}
