const validateVolume = shippingBox => {
	let interiorDimensions = JSON.parse(JSON.stringify(shippingBox.InteriorDimensions))
	let exteriorDimensions = JSON.parse(JSON.stringify(shippingBox.ExteriorDimensions))

	interiorDimensions = transformToNumbers(interiorDimensions)
	exteriorDimensions = transformToNumbers(exteriorDimensions)

	interiorDimensions.sort((a, b) => a - b)
	exteriorDimensions.sort((a, b) => a - b)

	if (interiorDimensions.length !== exteriorDimensions.length) {
		return [
			{
				message: "Interior and exterior dimensions don't have same size.",
				fields: [],
			},
		]
	}

	let errors = []
	for (let i = 0; i < interiorDimensions.length; i++) {
		const intDim = interiorDimensions[i]
		const extDim = exteriorDimensions[i]

		if (intDim > extDim) {
			let fields = findIds(interiorDimensions[i], 'interiorDimensions', shippingBox.InteriorDimensions)
			fields = fields.concat(findIds(exteriorDimensions[i], 'exteriorDimensions', shippingBox.ExteriorDimensions))

			errors.push({
				message:
					'Invalid value for interior dimension (' +
					interiorDimensions[i] +
					') must be smaller or equal than exterior (' +
					exteriorDimensions[i] +
					').',
				fields: fields,
			})
		}
	}

	return errors
}

const findIds = (targetValue, prefixId, array) => {
	let ids = []
	array.forEach((value, key) => {
		if (parseFloat(value) === targetValue) {
			ids.push(prefixId + key)
		}
	})

	return ids
}

const transformToNumbers = array => {
	const ints = []

	array.forEach(value => {
		ints.push(parseFloat(value))
	})

	return ints
}

module.exports = validateVolume
