import React from 'react'
import HrefLink from '../ex/HrefLink'
import MailUsLink from '../ex/MailUsLink'
import auth from '../auth/auth'

export default class HelpSectionAccess extends React.Component {
	renderQA(qa) {
		return (
			<div className="">
				<h5>
					<i className="label label-primary">Q</i> {qa.q}
				</h5>
				<p style={{ marginBottom: '20px' }}>{qa.a}</p>
			</div>
		)
	}

	render() {
		const userProfile = auth.getProfile()
		const subject = '[BoxMan] Access request for ' + userProfile.sub

		var qa = [
			{
				q: 'How can I request access to a fulfillment location?',
				a: (
					<span>
						Please email the squad at <MailUsLink subject={subject} />.
					</span>
				),
			},
			{
				q: 'What levels of access can users of the Shipping Box Management service have?',
				a: (
					<span>
						<i>Can Read</i>, <i>Can Edit</i>, and <i>Admin</i>. <i>Can Read</i> and <i>Can Edit</i> are set per
						fulfillment location per user, where <i>Admin</i> is per user only.
					</span>
				),
			},
			{
				q: 'Can I connect to the Shipping Box Management service through another service?',
				a: (
					<div>
						Yes, you can! Shipping Box Management has <HrefLink to="/docs/" title="RESTful APIs" /> that can be used.
						<br />
						As we use <HrefLink to="http://auth0.com" title="Auth0" /> for authentication purposes, you need to follow
						the following procedures to make an API call:
						<ul>
							<li>
								Get and record your{' '}
								<HrefLink
									to="https://auth0.com/docs/refresh-token#obtaining-a-refresh-token"
									title="refresh
                            token"
								/>{' '}
								from <HrefLink to="/api/refreshTokens" title="here" />.
							</li>
							<li>
								<HrefLink to="https://auth0.com/docs/refresh-token#using-a-refresh-token" title="Use" /> the refresh
								token to get an &nbsp;<code>id_token</code> (JWT).
							</li>
							<li>
								Add the following authorization header in the API call.
								<br />
								<kbd>Authorization: bearer &lt; your_id_token&gt;</kbd>
							</li>
						</ul>
					</div>
				),
			},
		]

		return (
			<div>
				<h3>Access</h3>
				<br />
				{qa.map(
					function (item) {
						return this.renderQA(item)
					}.bind(this),
				)}
			</div>
		)
	}
}
