'use strict'

import axios from 'axios'

const COAM_PERMISSIONS_URL = 'https://api.cimpress.io/auth/access-management/v1/principals/{principal}/permissions'
const requestTimeout = 1000

export default class Permissions {
	constructor(config) {
		if (
			!config ||
			!config.dataStore ||
			typeof config.dataStore.get !== 'function' ||
			typeof config.dataStore.set !== 'function' ||
			typeof config.dataStore.remove !== 'function'
		) {
			throw new Error('Unable to initialize Permissions: missing or invalid dataStore in config.')
		}

		if (config.requiredPermissions && !Array.isArray(config.requiredPermissions)) {
			throw new Error('Expected array for requiredPermissions.')
		}

		if (config.requiredResourceTypes && !Array.isArray(config.requiredResourceTypes)) {
			throw new Error('Expected array for requiredResourceTypes.')
		}

		this.dataStore = config.dataStore // Required
		this.storedResourceTypes = []
		this.requiredPermissions = config.requiredPermissions // Optional
		this.requiredResourceTypes = config.requiredResourceTypes // Optional
		this.permissionsUrl = config.permissionsUrl || COAM_PERMISSIONS_URL // Optional
		this.timeout = config.requestTimeout || requestTimeout
	}

	retrieve(token, sub, callback) {
		if (!callback || typeof callback !== 'function') {
			throw Error('Provided callback is not a function!')
		}

		if (!token || !sub) {
			return callback('Valid token and user sub required for retrieving permissions!')
		}

		const requestConfig = {
			method: 'get',
			url: this.permissionsUrl.replace('{principal}', sub),
			headers: {
				Authorization: 'Bearer ' + token,
			},
			timeout: this.timeout,
		}

		axios(requestConfig)
			.then(res => {
				const permissionsData = {}
				const coamData = res.data || {}

				const resourceTypes = Object.keys(coamData)
				resourceTypes.forEach(resourceType => {
					if (this.requiredResourceTypes && this.requiredResourceTypes.indexOf(resourceType) === -1) {
						return
					}

					permissionsData[resourceType] = permissionsData[resourceType] || {}

					const resources = coamData[resourceType] || []
					resources.forEach(resource => {
						resource.permissions.forEach(permission => {
							if (this.requiredPermissions && this.requiredPermissions.indexOf(permission) === -1) {
								return
							}

							permissionsData[resourceType][permission] = permissionsData[resourceType][permission] || []
							permissionsData[resourceType][permission].push(resource.identifier)
						})
					})
				})

				this.storedResourceTypes = []
				Object.keys(permissionsData).forEach(resourceType => {
					this.storedResourceTypes.push(resourceType)
					this.dataStore.set(resourceType, permissionsData[resourceType])
				})

				callback(null, permissionsData)
			})
			.catch(function (err) {
				callback(err)
			})
	}

	list(resourceType, permission) {
		const cachedResourceTypeData = this.dataStore.get(resourceType)
		if (!cachedResourceTypeData || !cachedResourceTypeData[permission]) {
			return []
		}

		return cachedResourceTypeData[permission]
	}

	check(resourceType, permission, resourceId) {
		const cachedResourceTypeData = this.dataStore.get(resourceType)

		if (!cachedResourceTypeData || !cachedResourceTypeData[permission]) {
			return false
		}

		const allowedResourceIds = cachedResourceTypeData[permission]

		return allowedResourceIds.indexOf(resourceId) !== -1 || allowedResourceIds.indexOf('*') !== -1
	}

	clear() {
		this.storedResourceTypes.forEach(resourceType => {
			this.dataStore.remove(resourceType)
		})

		this.storedResourceTypes = []
	}
}
