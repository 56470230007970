'use strict'

import React from 'react'
import { browserHistory } from 'react-router'
import Alert from '@cimpress/react-components/lib/Alert'
import Loading from './ex/Loading'
import auth from './auth/auth'
import permissions from './auth/permissions'
import FulfillmentLocationsList from '../qp-components/react-fl-fi-components/FulfillmentLocationsList'
import flLoader from '../utils/fulfillmentLocationsLoader'

export default class FulfillmentLocationsPage extends React.Component {
	constructor(props) {
		super(props)

		this.canReadLocations = permissions.canReadLocations()
		this.state = {}
	}

	componentDidMount() {
		const token = auth.getAccessToken()
		this.serverRequest = window.$.ajax({
			method: 'GET',
			url: `${process.env.REACT_APP_SERVICE_URL}/api/v1/fulfillmentLocations`,
			timeout: 5000,
			headers: {
				Authorization: `Bearer ${token}`,
			},
			success: function (data) {
				this.setState({
					configuredLocations: data,
				})
			}.bind(this),
			error: function (_error, _status, message) {
				this.setState({
					configuredLocations: null,
					error: message,
				})
			}.bind(this),
		})

		flLoader
			.loadLocationsData(auth.getAccessToken())
			.then(locations => {
				// TODO: If for some reason the code below fails - we would show false error
				this.setState({
					fulfillmentLocations: locations,
				})
			})
			.catch(errMessage => {
				this.setState({
					fulfillmentLocations: null,
					loadingFulfillmentLocations_error: errMessage,
				})
			})
	}

	componentWillUnmount() {
		this.serverRequest.abort()
	}

	clickOnLocation(location) {
		const locationCode = location.FulfillmentLocationId
		if (!this.canReadLocation(locationCode)) {
			return
		}

		var selected = this.state.configuredLocations.find(function (loc) {
			return loc.FulfillmentLocationId == locationCode
		})

		if (selected) {
			// Store and redirect *by default* to shipping boxes
			localStorage.setItem('selectedLocation', JSON.stringify(selected))

			browserHistory.push('/ui/shippingBoxes')
		} else {
			browserHistory.push(`/ui/fulfillmentLocations/${location.FulfillmentLocationId}/config`)
		}
	}

	onClickInitialize(locationId) {
		browserHistory.push(`/ui/fulfillmentLocations/${locationId}/config`)
	}

	canReadLocation(locationId) {
		return this.canReadLocations[locationId] || this.canReadLocations['*']
	}

	render() {
		var _this = this

		function unitsColumnFormatter(location) {
			var locationBoxInfo
			if (this.state.configuredLocations && this.state.configuredLocations.length > 0) {
				locationBoxInfo = this.state.configuredLocations.find(loc => {
					return loc.FulfillmentLocationId == location.FulfillmentLocationId
				})
			}
			if (locationBoxInfo) {
				let lengthTitle = `Units of Length configured to: ${locationBoxInfo.DefaultLengthUnit}`
				let weightTitle = `Units of Weight configured to: ${locationBoxInfo.DefaultWeightUnit}`
				let currencyTitle = `Currency configured to: ${locationBoxInfo.Currency}`
				return (
					<div style={{ align: 'left', 'white-space': 'nowrap' }}>
						<span
							className={'tag no-break'}
							data-toggle={'tooltip'}
							data-placement={'bottom'}
							data-original-title={lengthTitle}
							title={lengthTitle}
						>
							{locationBoxInfo.DefaultLengthUnit}
						</span>
						&nbsp;
						<span
							className={'tag no-break'}
							data-toggle={'tooltip'}
							data-placement={'bottom'}
							data-original-title={weightTitle}
							title={weightTitle}
						>
							{locationBoxInfo.DefaultWeightUnit}
						</span>
						&nbsp;
						<span
							className={'tag no-break'}
							data-toggle={'tooltip'}
							data-placement={'bottom'}
							data-original-title={currencyTitle}
							title={currencyTitle}
						>
							{locationBoxInfo.Currency}
						</span>
					</div>
				)
			} else {
				return (
					<div>
						<button
							className="btn btn-sm btn-outline-secondary"
							onClick={this.onClickInitialize.bind(this, location.FulfillmentLocationId)}
						>
							<i className="fa fa-plus" />
							&nbsp;Initialize
						</button>
					</div>
				)
			}
		}

		var props = {}
		if (this.state.loadingFulfillmentLocations_error) {
			props.message = <span>{this.state.loadingFulfillmentLocations_error}</span>
			return <Alert {...props} />
		} else if (
			this.state.configuredLocations &&
			this.state.fulfillmentLocations &&
			this.state.fulfillmentLocations.length > 0
		) {
			return (
				<div>
					<div className="row qp-bs-table-row">
						<div className="col-md-12">
							<FulfillmentLocationsList
								fulfillmentLocations={this.state.fulfillmentLocations}
								onSelectionClicked={location => {
									_this.clickOnLocation(location)
								}}
								maxItemsToShowAtOnce={20}
								showPartialResult={true}
								showPartialResultPagingTop={true}
								additionalColumnTitle={'Units'}
								additionalColumnRenderer={unitsColumnFormatter.bind(_this)}
								showCancelButton={false}
							/>
						</div>
					</div>
				</div>
			)
		} else if (this.state.fulfillmentLocations && this.state.fulfillmentLocations.length == 0) {
			props.message = (
				<span>
					You do not have access to view or manage boxes in any fulfillment locations. To request access, please reach
					out to either the person responsible for managing the fulfiller, or email{' '}
					<a href="mailto:PlatformSupport@cimpress.com">PlatformSupport@cimpress.com</a>
				</span>
			)
			return <Alert {...props} />
		} else {
			if (this.state.error) {
				var errorBoxProps = {}
				errorBoxProps.message = <span>Failed to retrieve allowed fulfillment locations. Error: {this.state.error}</span>
				return <Alert {...errorBoxProps} />
			} else {
				return <Loading />
			}
		}
	}
}
