'use strict'

import React from 'react'
import { render } from 'react-dom'
import ShippingBoxApp from './ShippingBoxApp'
import FulfillmentLocationsPage from './FulfillmentLocationsPage'
import ConfigFulfillmentLocationPage from './ConfigFulfillmentLocationPage'
import ListShippingBoxesPage from './ListShippingBoxesPage'
import ShippingBoxPage from './ShippingBoxPage'
import MultiBoxTagsAdd from './MultiBoxTagsAdd'
import MultiBoxTagsDelete from './MultiBoxTagsDelete'
import ContactUsWrapper from './ContactUsPage'
import RulesPage from './RulesPage'
import HelpPage from './HelpPage'
import HelpSectionFaq from './help/HelpSectionFaq.jsx'
import HelpSectionShippingBoxProperties from './help/HelpSectionShippingBoxProperties'
import HelpSectionEligibility from './help/HelpSectionEligibility'
import HelpSectionShippingBoxTags from './help/HelpSectionShippingBoxTags'
import HelpSectionAccess from './help/HelpSectionAccess'
import { CssLoader } from '@cimpress/react-components'
import { Router, Route, IndexRedirect, IndexRoute, browserHistory } from 'react-router'
import 'react-select/dist/react-select.css'
import 'react-bootstrap-table/css/react-bootstrap-table.css'
import '../stylesheets/style.css'

render(
	<CssLoader>
		<Router history={browserHistory}>
			<Route path="/">
				<IndexRedirect to="/ui" />
			</Route>
			<Route path="/ui" component={ShippingBoxApp}>
				<IndexRedirect to="/ui/fulfillmentLocations" />
				<Route path="callback" component={FulfillmentLocationsPage} />
				<Route path="fulfillmentLocations">
					<IndexRedirect to="/ui/fulfillmentLocations/list" />
					<Route path="list" component={FulfillmentLocationsPage} />
					<Route path=":fulfillmentLocationId/config" component={ConfigFulfillmentLocationPage} />
				</Route>
				<Route path="shippingBoxes">
					<IndexRoute component={ListShippingBoxesPage} />
					<Route path=":shippingBoxId" component={ShippingBoxPage} />
					<Route path="advanced">
						<Route path="multiboxTagsAdd" component={MultiBoxTagsAdd} />
						<Route path="multiboxTagsDelete" component={MultiBoxTagsDelete} />
					</Route>
				</Route>
				<Route path="help" component={HelpPage}>
					<IndexRedirect to="/ui/help/boxProperties" />
					<Route path="faq" component={HelpSectionFaq} />
					<Route path="eligibility" component={HelpSectionEligibility} />
					<Route path="boxProperties" component={HelpSectionShippingBoxProperties} />
					<Route path="boxTags" component={HelpSectionShippingBoxTags} />
					<Route path="access" component={HelpSectionAccess} />
				</Route>
				<Route path="contactUs" component={ContactUsWrapper} />
				<Route path="rules" component={RulesPage} />
			</Route>
		</Router>
	</CssLoader>,
	document.getElementById('reactApp'),
)
