'use strict'

import React from 'react'
import PropTypes from 'prop-types'
import { browserHistory, Link } from 'react-router'
import ShippingBoxProperties from './ShippingBoxProperties'
import ShippingBoxTags from './ShippingBoxTags'
import ShippingBoxHistory from './ShippingBoxHistory'
import ConfirmBox from './ex/ConfirmBox'
import Loading from './ex/Loading'
import Alert from '@cimpress/react-components/lib/Alert'
import Utils from '../utils/Utils'
import auth from './auth/auth'
import permissions from './auth/permissions'

export default class ShippingBoxPage extends React.Component {
	constructor(props, context) {
		super(props, context)

		var isNew = window.location.pathname.split('/')[3].substring(0, 3) === 'new'

		var showTab = 'core'
		if (window.location.hash == '#history' && !isNew) {
			showTab = 'history'
		} else if (window.location.hash == '#tags' && !isNew) {
			showTab = 'tags'
		}

		var selectedLocation = localStorage.getItem('selectedLocation')
		var location = null
		if (selectedLocation) {
			location = JSON.parse(selectedLocation)
		}
		if (location == null) {
			browserHistory.push('/ui/fulfillmentLocations')
		}

		this.state = {
			shippingBox: null,
			shippingBoxHistory: null,
			location: location,
			isEdit: false,
			isNew: isNew,
			showTab: showTab,
		}
	}

	componentDidMount() {
		var shippingBoxId = this.props.params.shippingBoxId
		if (!this.state.isNew) {
			this.getShippingBox(shippingBoxId)
		}
		Utils.setAsyncRouteLeaveHook(this)
	}

	componentWillUnmount() {
		if (this.serverRequestGetShippingBox) {
			this.serverRequestGetShippingBox.abort()
		}
	}

	getShippingBox(shippingBoxId) {
		const token = auth.getAccessToken()
		this.serverRequestGetShippingBox = window.$.ajax({
			method: 'GET',
			url: `${process.env.REACT_APP_SERVICE_URL}/api/v1/shippingBoxes/${shippingBoxId}`,
			timeout: 3000,

			headers: {
				Authorization: `Bearer ${token}`,
			},

			success: function (data) {
				this.setState({ shippingBox: data })
			}.bind(this),

			error: function (error) {
				this.setState({ error: error.responseText })
			}.bind(this),
		})
	}

	onClickTab(tab, e) {
		if (this.state.isNew) {
			this.setState({ showTab: 'core' })
		} else {
			if (this.containsChanges()) {
				this.confirmLeave(
					function (leave) {
						if (leave) {
							this.setState({ showTab: tab })
						}
					}.bind(this),
				)
			} else {
				this.setState({ showTab: tab })
			}
		}
		e.preventDefault()
	}

	containsChanges() {
		switch (this.state.showTab) {
			case 'core':
				return this.shippingBoxCore.containsChanges()
			case 'tags':
				return this.shippingBoxTag.containsChanges()
			default:
				return false
		}
	}

	afterTagUpdated(newShippingBox) {
		this.setState({
			shippingBox: newShippingBox,
		})
	}

	renderTabs(tabs) {
		return (
			<ul className="nav nav-pills">
				{tabs.map(
					function (t, i) {
						var isActive = t.key == this.state.showTab
						var isEnabled = !this.state.isNew

						var title = (
							<a href={'#' + t.key} onClick={this.onClickTab.bind(this, t.key)}>
								{t.caption}
							</a>
						)
						if (!isEnabled || isActive) {
							title = <a>{t.caption}</a>
						}

						return (
							<li key={i} className={isActive ? 'active' : isEnabled ? '' : 'disabled'}>
								{title}
							</li>
						)
					}.bind(this),
				)}
			</ul>
		)
	}

	stateToHideConfirmBox() {
		return {
			confirmBoxShow: false,
			confirmBoxOnCancel: this.onConfirmBoxCancel.bind(this),
		}
	}

	onConfirmBoxCancel() {
		this.setState(this.stateToHideConfirmBox())
	}

	onLeaveConfirmed() {
		this.setState(this.stateToHideConfirmBox())
		this.state.leaveCallback(true)
	}

	onLeaveCancelled() {
		this.setState(this.stateToHideConfirmBox())
		this.state.leaveCallback(false)
	}

	confirmLeave(callback) {
		this.setState({
			leaveCallback: callback,
			confirmBoxShow: true,
			confirmBoxTitle: 'Leave confirmation',
			confirmBoxBody: 'There are unsaved changes. Would you like to navigate out of this page and lose them?',
			confirmBoxOnConfirm: this.onLeaveConfirmed.bind(this),
			confirmBoxOnCancel: this.onLeaveCancelled.bind(this),
		})
	}

	render() {
		const canManage = this.state.location && permissions.canManageLocation(this.state.location.FulfillmentLocationId)

		var shippingBoxId = -1
		if (this.state.shippingBox) {
			shippingBoxId = this.state.shippingBox.ShippingBoxId
		}
		if (!this.state.shippingBox && !this.state.isNew) {
			return <Loading />
		}

		var content = <Loading />
		if (this.state.showTab == 'core') {
			content = (
				<ShippingBoxProperties
					ref={c => {
						this.shippingBoxCore = c
					}}
					shippingBox={this.state.shippingBox}
					location={this.state.location}
					disabled={!canManage}
				/>
			)
		}
		if (this.state.showTab == 'tags') {
			content = (
				<ShippingBoxTags
					ref={c => {
						this.shippingBoxTag = c
					}}
					shippingBox={this.state.shippingBox}
					afterUpdated={this.afterTagUpdated.bind(this)}
					canManage={canManage}
				/>
			)
		}

		if (this.state.showTab == 'history') {
			content = <ShippingBoxHistory shippingBoxId={shippingBoxId} />
		}

		if (this.state.location) {
			var boxDescription = ''
			if (this.state.shippingBox) {
				boxDescription = this.state.shippingBox.Description
			}

			return (
				<div>
					<ol className="breadcrumb">
						<li>{this.state.location.Description}</li>
						<li>
							<Link to={'/ui/shippingBoxes/'}>Shipping Boxes</Link>
						</li>
						<li className="active">{this.state.isNew ? 'Create new Shipping Box' : boxDescription}</li>
					</ol>

					<ConfirmBox
						show={this.state.confirmBoxShow}
						title={this.state.confirmBoxTitle}
						body={this.state.confirmBoxBody}
						onCancel={this.state.confirmBoxOnCancel || this.onConfirmBoxCancel.bind(this)}
						onConfirm={this.state.confirmBoxOnConfirm ? this.state.confirmBoxOnConfirm : null}
					/>

					{this.renderTabs([
						{
							key: 'core',
							caption: 'Properties',
						},
						{
							key: 'tags',
							caption: 'Tags',
						},
						{
							key: 'history',
							caption: 'History',
						},
					])}

					<div className="qp-tab-content">{content}</div>
				</div>
			)
		} else {
			if (this.state.error) {
				return <Alert message={this.state.error} />
			} else {
				return <Loading />
			}
		}
	}
}

ShippingBoxPage.contextTypes = {
	router: PropTypes.object.isRequired,
}
