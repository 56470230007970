'use strict'

import React from 'react'
import PropTypes from 'prop-types'
import { browserHistory } from 'react-router'
import boxValidator from '../utils/boxValidator'
import ConfirmBox from './ex/ConfirmBox'
import Alert from '@cimpress/react-components/lib/Alert'
import Loading from './ex/Loading'
import TextFieldWithValidation from './ex/TextFieldWithValidation'
import RuleItem from './RuleItem'
import Utils from '../utils/Utils'
import validator from '../qp-components/qp-validators'
import Snackbar from '@cimpress/react-components/lib/Snackbar'
import { Select } from '@cimpress/react-components'
import FileSaver from 'file-saver'
import auth from './auth/auth'
import permissions from './auth/permissions'

export default class Rules extends React.Component {
	constructor(props, context) {
		super(props, context)

		this.state = {
			originalRules: null,
			rules: null,
			isSaving: false,
			isImporting: false,
			editingRuleIndex: null,
			deletingRuleIndex: null,
			location: null,
		}
	}

	getShippingBoxes(locationCode) {
		const token = auth.getAccessToken()
		this.serverRequestGetShippingBoxes = window.$.ajax({
			method: 'GET',
			url: `${process.env.REACT_APP_SERVICE_URL}/api/v1/shippingBoxes?fulfillmentLocationId=${locationCode}`,
			timeout: 3000,

			headers: {
				Authorization: `Bearer ${token}`,
			},

			success: function (shippingBoxesData) {
				var boxesPerTags = { '-': 0 }
				var activeBoxesPerTags = { '-': 0 }
				shippingBoxesData.map(function (box) {
					box.Tags.map(function (tag) {
						if (boxesPerTags[tag.Key]) {
							boxesPerTags[tag.Key]++
						} else {
							boxesPerTags[tag.Key] = 1
						}

						if (box.Active) {
							if (activeBoxesPerTags[tag.Key]) {
								activeBoxesPerTags[tag.Key]++
							} else {
								activeBoxesPerTags[tag.Key] = 1
							}
						} else if (!activeBoxesPerTags[tag.Key]) {
							activeBoxesPerTags[tag.Key] = 0
						}
					})
				})

				this.setState({
					boxesPerGroupTags: boxesPerTags,
					activeBoxesPerGroupTags: activeBoxesPerTags,
				})
			}.bind(this),

			error: function (_error, _status, message) {
				this.setState({
					boxesPerGroupTags: {},
					activeBoxesPerGroupTags: null,
					error: message,
				})
			}.bind(this),
		})
	}

	getEligibilityRules(locationCode) {
		const token = auth.getAccessToken()
		this.serverRequestGetEligibilityRules = window.$.ajax({
			type: 'GET',
			url: `${process.env.REACT_APP_SERVICE_URL}/api/v1/eligibilityRules?fulfillmentLocationId=${locationCode}`,
			timeout: 10000,
			dataType: 'json',
			headers: {
				Authorization: `Bearer ${token}`,
			},

			success: function (rulesData) {
				var strRules = JSON.stringify(rulesData)
				this.setState({
					rules: JSON.parse(strRules),
					originalRules: JSON.parse(strRules),
				})
			}.bind(this),

			error: function (error) {
				if (error.status === 404) {
					this.setState({
						rules: [],
						originalRules: [],
					})
				} else {
					this.setState({ error: error.responseText })
				}
			}.bind(this),
		})
	}

	putEligibilityRules(rulesPutData) {
		const token = auth.getAccessToken()
		this.serverRequestPutEligibilityRules = window.$.ajax({
			type: 'PUT',
			url: `${process.env.REACT_APP_SERVICE_URL}/api/v1/eligibilityRules`,
			timeout: 10000,
			data: JSON.stringify(rulesPutData),
			contentType: 'application/json',
			dataType: 'json',
			headers: {
				Authorization: `Bearer ${token}`,
			},

			success: function (rulesData) {
				this.setState(
					Object.assign(
						{},
						{
							rules: JSON.parse(JSON.stringify(rulesData)),
							originalRules: JSON.parse(JSON.stringify(rulesData)),
							reason: '',
						},
						this.stateToShowSnackBar('Rules saved successfully.', 'info'),
						this.stateToHideConfirmBox(),
					),
				)
			}.bind(this),

			error: function (error) {
				this.setState(
					Object.assign(
						{},
						{
							error: error.responseText,
							reason: '',
						},
						this.stateToShowSnackBar(this.formatErrorMessage(error)),
						this.stateToHideConfirmBox(),
					),
				)
			}.bind(this),
		})
	}

	componentWillMount() {
		var selectedLocation = localStorage.getItem('selectedLocation')
		var location = null
		if (selectedLocation) {
			location = JSON.parse(selectedLocation)
		}

		if (!location) {
			browserHistory.push('/ui/fulfillmentLocations')
			return
		}

		this.setState({ location: location })

		this.getShippingBoxes(location.FulfillmentLocationId)
		this.getEligibilityRules(location.FulfillmentLocationId)
		Utils.setAsyncRouteLeaveHook(this)
	}

	containsChanges() {
		return (
			this.state.editingRuleIndex != null ||
			JSON.stringify(this.state.originalRules) != JSON.stringify(this.state.rules)
		)
	}

	onLeaveConfirmed() {
		this.setState(this.stateToHideConfirmBox())
		this.state.leaveCallback(true)
	}

	onLeaveCancelled() {
		this.setState(this.stateToHideConfirmBox())
		this.state.leaveCallback(false)
	}

	confirmLeave(callback) {
		this.setState({
			leaveCallback: callback,
			confirmBoxShow: true,
			confirmBoxTitle: 'Leave confirmation',
			confirmBoxBody: 'There are unsaved changes. Would you like to navigate out of this page and lose them?',
			confirmBoxOnConfirm: this.onLeaveConfirmed.bind(this),
			confirmBoxOnCancel: this.onLeaveCancelled.bind(this),
		})
	}

	componentWillUnmount() {
		if (this.serverRequestGetEligibilityRules) {
			this.serverRequestGetEligibilityRules.abort()
		}

		if (this.serverRequestPutEligibilityRules) {
			this.serverRequestPutEligibilityRules.abort()
		}

		if (this.serverRequestGetEligibilityRulesCsv) {
			this.serverRequestGetEligibilityRulesCsv.abort()
		}

		if (this.serverRequestGetShippingBoxes) {
			this.serverRequestGetShippingBoxes.abort()
		}

		if (this.serverRequestImportEligibilityRules) {
			this.serverRequestImportEligibilityRules.abort()
		}
	}

	stateToShowSnackBar(message, type = 'danger') {
		return {
			snackBarShow: true,
			snackBarType: type,
			snackBarBody: message,
		}
	}

	onHideSnackbar() {
		this.setState({
			snackBarShow: false,
		})
	}

	stateToHideConfirmBox() {
		return {
			confirmBoxShow: false,
			confirmBoxOnCancel: this.onConfirmBoxCancel.bind(this),
		}
	}

	onConfirmBoxCancel() {
		this.setState(this.stateToHideConfirmBox())
	}

	render() {
		const isViperLocation = this.state.location && Utils.isViperLocation(this.state.location.FulfillmentLocationId)
		const canManage = this.state.location && permissions.canManageLocation(this.state.location.FulfillmentLocationId)

		let readonlyTooltip = null
		if (!canManage) {
			readonlyTooltip = 'Read-only mode active'
		}

		var editingRuleForm = ''
		if (this.state.editingRuleIndex) {
			editingRuleForm = (
				<div className="card">
					<div className="card-block">
						<TextFieldWithValidation
							value={this.state.editingRuleCondition}
							label="When the following condition is matched"
							handleValueChange={this.onChangeValue.bind(this, 'editingRuleCondition')}
							name="editingRuleCondition"
							validator={
								isViperLocation
									? boxValidator.validateEligibilityCondition
									: boxValidator.validateBoxRecommendationCondition
							}
							helpMessage="Please enter valid condition"
							disabled={this.props.disabled}
						/>
						<div className="form-group">
							<Select
								label="then select shipping boxes tagged with"
								value={this.state.editingRuleTag}
								onChange={this.onReactSelectChangeValue.bind(this, 'editingRuleTag')}
								ref={c => (this.editingTagRef = c)}
								options={Object.keys(this.state.boxesPerGroupTags)
									.sort(function (a, b) {
										return a.localeCompare(b)
									})
									.map(
										function (tag) {
											return {
												value: tag,
												label: (
													<span>
														<code>{tag}</code>&nbsp;
														<span className="text-primary">
															{this.state.boxesPerGroupTags[tag]}
															&nbsp;box(es),&nbsp;
															{this.state.activeBoxesPerGroupTags[tag]} active
														</span>
													</span>
												),
											}
										}.bind(this),
									)}
							/>
						</div>
						<div className="text-right">
							<button className="btn btn-default" onClick={this.onCancelRuleBtnClick.bind(this)} title="Cancel">
								<i className="fa fa-ban" />
								&nbsp;Cancel
							</button>
							&nbsp;&nbsp;
							<button className="btn btn-primary" onClick={this.onSaveRuleBtnClick.bind(this)} title="Save">
								<i className="fa fa-floppy-o" aria-hidden="true" />
								&nbsp;Save
							</button>
						</div>
					</div>
				</div>
			)
		}

		var addButton = ''
		var importButton = ''
		var exportButton = ''
		var saveRuleSet = ''

		var importReasonSet = (
			<div className="qp-non-tab-content qp-margin-bottom">
				<div className="qp-margin-bottom">TBD: Add some help here</div>
				<div>
					<label className="btn btn-primary">
						<i className="fa fa-upload" />
						&nbsp;&nbsp;Select File to Import
						<input type="file" onChange={this.onSelectImportFileBtnClick.bind(this)} style={{ display: 'none' }} />
					</label>
					&nbsp;&nbsp;
					<em style={{ lineHeight: '40px' }}>
						<code>{this.state.importFile ? this.state.importFile.name : 'No file selected'}</code>
					</em>
				</div>
				<br />
				<TextFieldWithValidation
					value={this.state.importReason}
					label="Import Reason"
					handleValueChange={this.onChangeValue.bind(this, 'importReason')}
					name="importReason"
					helpMessage="Please provide a reason for this import change."
					validator={validator.validateNonEmptyString}
				/>

				<p className="text-right">
					<button className="btn btn-default" onClick={this.onCancelImportBtnClick.bind(this)}>
						<i className="fa fa-ban" />
						&nbsp;Cancel
					</button>
					&nbsp;&nbsp;
					<button
						className="btn btn-primary"
						disabled={!this.state.importFile || !this.state.importReason}
						onClick={this.onSaveImportReasonBtnClick.bind(this)}
					>
						<i className="fa fa-floppy-o" aria-hidden="true" />
						&nbsp;Import Rules
					</button>
				</p>
			</div>
		)
		if (!this.state.editingRuleIndex) {
			saveRuleSet = (
				<div>
					<div className="card">
						<div className="card-block">
							<TextFieldWithValidation
								value={this.state.reason}
								label="Reason"
								handleValueChange={this.onChangeValue.bind(this, 'reason')}
								name="reason"
								helpMessage="Please provide a reason for this change."
								validator={validator.validateNonEmptyString}
							/>
							<p className="text-right">
								<button className="btn btn-default" onClick={this.onCancelBtnClick.bind(this)}>
									<i className="fa fa-ban" />
									&nbsp;Cancel
								</button>
								&nbsp;&nbsp;
								<button
									className="btn btn-primary"
									disabled={!this.state.reason}
									onClick={!this.state.isSaving ? this.onSaveBtnClick.bind(this) : null}
								>
									<i className="fa fa-floppy-o" aria-hidden="true" />
									&nbsp;Save
								</button>
							</p>
						</div>
					</div>
				</div>
			)

			addButton = (
				<button
					className="btn btn-default"
					onClick={this.onAddRuleBtnClick.bind(this)}
					title={readonlyTooltip}
					disabled={!canManage || this.state.isImporting}
				>
					<i className="fa fa-plus" />
					&nbsp;Add
				</button>
			)

			importButton = (
				<button
					className="btn btn-primary"
					onClick={this.onImportBtnClick.bind(this)}
					title={readonlyTooltip}
					disabled={
						!canManage ||
						this.state.isImporting ||
						JSON.stringify(this.state.originalRules) != JSON.stringify(this.state.rules)
					}
				>
					<i className="fa fa-upload" />
					&nbsp;Import
				</button>
			)
			exportButton = (
				<button
					id={'btn-export-eligibility-rules'}
					className="btn btn-primary"
					disabled={
						this.state.isImporting || JSON.stringify(this.state.originalRules) != JSON.stringify(this.state.rules)
					}
					onClick={this.onExportBtnClick.bind(this)}
				>
					<i className="fa fa-download" />
					&nbsp;Export
				</button>
			)
		}

		if (JSON.stringify(this.state.originalRules) == JSON.stringify(this.state.rules)) {
			saveRuleSet = ''
		}

		if (this.state.rules !== null) {
			var locationDescription = this.state.location ? this.state.location.Description : ''

			return (
				<div>
					<ol className="breadcrumb">
						<li>{locationDescription}</li>
						<li className="active">Eligibility rules</li>
					</ol>

					{editingRuleForm}
					<Snackbar
						show={this.state.snackBarShow || false}
						onHideSnackbar={this.onHideSnackbar.bind(this)}
						bsStyle={this.state.snackBarType || 'danger'}
						delay="8000"
					>
						{this.state.snackBarBody}
					</Snackbar>

					<ConfirmBox
						show={this.state.confirmBoxShow}
						title={this.state.confirmBoxTitle}
						body={this.state.confirmBoxBody}
						onCancel={this.state.confirmBoxOnCancel || this.onConfirmBoxCancel.bind(this)}
						onConfirm={this.state.confirmBoxOnConfirm ? this.state.confirmBoxOnConfirm : null}
					/>

					{!this.state.isImporting ? (
						<div className="qp-margin-bottom">
							<div className="btn-group">{addButton}</div>
							&nbsp;
							<div className="btn-group">
								{importButton}
								{exportButton}
							</div>
						</div>
					) : (
						''
					)}

					{!this.state.isImporting ? (
						this.state.rules.length == 0 ? (
							<Alert type="info" message="No rules defined" />
						) : (
							<div className="list-group">
								{this.state.rules.map(
									function (rule) {
										if (this.state.isNewRule && rule.Index == this.state.rules.length) {
											return (
												<div className="list-group-item active">
													<em>The new rule would be placed in this position initially.</em>
												</div>
											)
										}

										let editingTooltip = null
										if (this.state.editingRuleIndex) {
											editingTooltip = 'Disabled while editing a rule'
										}

										return (
											<RuleItem
												key={rule.Index}
												onMoveUpBtnClick={this.onMoveUpBtnClick.bind(this)}
												onEditRuleBtnClick={this.onEditRuleBtnClick.bind(this)}
												onDeleteRuleBtnClick={this.onDeleteRuleBtnClick.bind(this)}
												rule={rule}
												numberOfBoxesWithTheSameTag={
													this.state.boxesPerGroupTags ? this.state.boxesPerGroupTags[rule.Tag] : null
												}
												numberOfActiveBoxesWithTheSameTag={
													this.state.activeBoxesPerGroupTags ? this.state.activeBoxesPerGroupTags[rule.Tag] : null
												}
												lastRuleIndex={this.state.rules.length}
												isActive={rule.Index === this.state.editingRuleIndex}
												isDisabled={!canManage || this.state.editingRuleIndex || false}
												disabledReason={readonlyTooltip || editingTooltip}
											/>
										)
									}.bind(this),
								)}
							</div>
						)
					) : (
						importReasonSet
					)}

					{saveRuleSet}
				</div>
			)
		} else {
			// TODO: Fix me... we can't handle all errors with a single error (we have multiple calls to BoxMan)
			if (this.state.error) {
				var props = {}
				props.message = <span>Failed to retrieve shipping box eligibility rules. Error: {this.state.error}</span>
				return <Alert {...props} />
			} else {
				return <Loading />
			}
		}
	}

	onReactSelectChangeValue(key, event) {
		this.onChangeValue(key, event.value)
	}

	onChangeValue(key, value) {
		var update = {}
		update[key] = value
		this.setState(update)
	}

	onAddRuleBtnClick() {
		var newRuleIndex = this.state.rules.length + 1

		var newRules = JSON.parse(JSON.stringify(this.state.rules))
		newRules.push({
			Index: newRuleIndex,
			Condition: '',
			Tag: '',
		})

		this.setState({
			rules: newRules,
			isNewRule: true,
			editingRuleIndex: newRuleIndex,
			editingRuleCondition: '',
			editingRuleTag: '',
		})
	}

	onImportBtnClick() {
		this.setState({
			isSaving: true,
			isImporting: true,
			importReason: null,
			importFile: null,
		})
	}

	onExportBtnClick() {
		const token = auth.getAccessToken()
		const locationId = this.state.location.FulfillmentLocationId

		this.serverRequestGetEligibilityRulesCsv = window.$.ajax({
			method: 'GET',
			url: `${process.env.REACT_APP_SERVICE_URL}/api/v1/eligibilityRules/csv?fulfillmentLocationId=${locationId}`,
			timeout: 3000,
			headers: {
				Authorization: `Bearer ${token}`,
			},
			success: function (rulesData) {
				const saveData = new Blob([rulesData], {
					type: 'text/plain;charset=utf-8',
				})
				const fileName = `${locationId}.eligibilityRules.csv`
				FileSaver.saveAs(saveData, fileName, true)
			},
			error: function (_error, _status, message) {
				this.setState({
					error: message,
				})
			}.bind(this),
		})
	}

	onSelectImportFileBtnClick(event) {
		if (!event.target.value) {
			return
		}

		this.setState({
			importFile: event.target.files[0],
		})

		event.target.value = null
		event.preventDefault()
	}

	importRules(file) {
		const token = auth.getAccessToken()

		var formData = new FormData()
		formData.append('FulfillmentLocationId', this.state.location.FulfillmentLocationId)
		formData.append('Reason', this.state.importReason)
		formData.append('EligibilityRulesImportFile', file)

		this.serverRequestImportEligibilityRules = window.$.ajax({
			method: 'POST',
			url: `${process.env.REACT_APP_SERVICE_URL}/api/v1/eligibilityRules/csv`,
			processData: false,
			contentType: false,
			data: formData,
			timeOut: 10000,
			headers: {
				Authorization: `Bearer ${token}`,
			},
			success: function (data) {
				this.setState(
					Object.assign(
						{},
						{
							isSaving: false,
							isImporting: false,
							importReason: '',
							importFile: null,
							rules: JSON.parse(JSON.stringify(data)),
							originalRules: JSON.parse(JSON.stringify(data)),
						},
						this.stateToHideConfirmBox(),
					),
				)
			}.bind(this),
			error: function (error) {
				this.setState(
					Object.assign(
						{},
						{
							isSaving: false,
							isImporting: false,
							importReason: '',
							importFile: null,
						},
						this.stateToShowSnackBar(this.formatErrorMessage(error)),
						this.stateToHideConfirmBox(),
					),
				)
			}.bind(this),
		})
	}

	onSaveImportReasonBtnClick() {
		this.setState({
			confirmBoxShow: true,
			confirmBoxTitle: 'Confirm import',
			confirmBoxBody: 'Imported rules will replace all existing eligibility rules. Do you wish to continue?',
			confirmBoxOnConfirm: this.saveImport.bind(this),
		})
	}

	onCancelImportBtnClick() {
		if (this.state.importFile) {
			this.setState({
				confirmBoxShow: true,
				confirmBoxTitle: 'Confirm import cancellation',
				confirmBoxBody: 'Unsaved changes will be lost. Do you wish to continue?',
				confirmBoxOnConfirm: this.cancelImport.bind(this),
			})
		} else {
			this.cancelImport()
		}
	}

	saveImport() {
		this.setState(this.stateToHideConfirmBox())
		this.importRules(this.state.importFile)
	}

	cancelImport() {
		this.setState(
			Object.assign(
				{},
				{
					importFile: null,
					isSaving: false,
					isImporting: false,
					importReason: '',
				},
				this.stateToHideConfirmBox(),
			),
		)
	}

	onMoveUpBtnClick(index) {
		// 1-based index 1==at the top (no more up)
		if (index < 2) {
			// we can't move the top one up
			return
		}

		// copy!
		var newRules = this.state.rules.slice()

		for (var i = 0; i < newRules.length; i++) {
			if (newRules[i].Index === index) {
				newRules[i].Index--
			} else if (newRules[i].Index === index - 1) {
				newRules[i].Index++
			}
		}

		// make sure the rules are ordered
		newRules = newRules.sort(function (a, b) {
			return a.Index - b.Index
		})

		// update
		this.setState({ rules: newRules })
	}

	onDeleteRuleBtnClick(index) {
		this.setState({
			deletingRuleIndex: index,
			confirmBoxShow: true,
			confirmBoxTitle: 'Confirm delete rule',
			confirmBoxBody: 'Are you sure you want to delete this rule?',
			confirmBoxOnConfirm: this.deleteRule.bind(this),
		})
	}

	deleteRule() {
		var ruleIndex = this.state.deletingRuleIndex

		// delete
		var newRules = this.state.rules.filter(function (element) {
			return element.Index !== ruleIndex
		})

		// re-index
		for (var i = 0; i < newRules.length; i++) {
			if (newRules[i].Index > ruleIndex) {
				newRules[i].Index--
			}
		}

		// make sure the rules are ordered
		newRules = newRules.sort(function (a, b) {
			return a.Index - b.Index
		})

		// update
		this.setState(
			Object.assign(
				{},
				{
					rules: newRules,
					deletingRuleIndex: null,
				},
				this.stateToHideConfirmBox(),
			),
		)
	}

	onCancelRuleBtnClick() {
		var editingRuleIndex = this.state.editingRuleIndex
		var newState = this.stateToHideConfirmBox()

		if (this.state.isNewRule) {
			// delete
			var newRules = this.state.rules.filter(function (element) {
				return element.Index !== editingRuleIndex
			})

			newState = Object.assign({}, newState, {
				isNewRule: false,
				rules: newRules,
				editingRuleIndex: null,
				editingRuleCondition: null,
				editingRuleTag: null,
			})
		}

		newState = Object.assign({}, newState, {
			editingRuleIndex: null,
			editingRuleCondition: null,
			editingRuleTag: null,
		})

		this.setState(newState)
	}

	onEditRuleBtnClick(index) {
		var rule = this.state.rules.filter(function (r) {
			return r.Index == index
		})[0]

		var boxesPerGroupTags = JSON.parse(JSON.stringify(this.state.boxesPerGroupTags))
		if (!boxesPerGroupTags[rule.Tag]) {
			boxesPerGroupTags[rule.Tag] = 0
		}

		this.setState({
			editingRuleIndex: index,
			editingRuleCondition: rule.Condition,
			editingRuleTag: rule.Tag,
			boxesPerGroupTags: boxesPerGroupTags,
		})
	}

	onSaveRuleBtnClick() {
		if (!this.state.editingRuleTag || this.state.editingRuleTag == '-') {
			this.editingTagRef.focus()
			return
		}

		this.setState({
			editingRuleIndex: null,
			isNewRule: false,
		})

		// copy
		var newRules = JSON.parse(JSON.stringify(this.state.rules))

		// update
		for (var i = 0; i < newRules.length; i++) {
			if (newRules[i].Index === this.state.editingRuleIndex) {
				newRules[i].Condition = this.state.editingRuleCondition
				newRules[i].Tag = this.state.editingRuleTag
			}
		}

		// update
		this.setState({ rules: newRules })
	}

	onSaveBtnClick() {
		if (JSON.stringify(this.state.originalRules) == JSON.stringify(this.state.rules)) {
			return //TODO: Inform user?
		}

		this.setState({
			confirmBoxShow: true,
			confirmBoxTitle: 'Confirm save',
			confirmBoxBody: 'Changes done will take effect immediately. Do you wish to continue?',
			confirmBoxOnConfirm: this.save.bind(this),
		})
	}

	save() {
		this.setState(this.stateToHideConfirmBox())

		this.putEligibilityRules({
			Rules: this.state.rules,
			FulfillmentLocationId: this.state.location.FulfillmentLocationId,
			Reason: this.state.reason,
		})
	}

	onCancelBtnClick() {
		if (JSON.stringify(this.state.originalRules) != JSON.stringify(this.state.rules)) {
			this.setState({
				confirmBoxShow: true,
				confirmBoxTitle: 'Confirm cancellation',
				confirmBoxBody: 'Unsaved changes will be lost. Do you wish to continue?',
				confirmBoxOnConfirm: this.cancel.bind(this),
			})
		}
	}

	cancel() {
		this.setState(
			Object.assign(
				{},
				{
					rules: JSON.parse(JSON.stringify(this.state.originalRules)),
				},
				this.stateToHideConfirmBox(),
			),
		)
	}

	formatErrorMessage(error) {
		if (error.responseJSON) {
			return error.responseJSON.results.errors.map(function (each) {
				return each.code + ': ' + each.description + '(' + each.message + ')<br/><br/>'
			})
		} else if (error.responseText) {
			return error.responseText
		}
		return error
	}
}

Rules.contextTypes = {
	router: PropTypes.object.isRequired,
}

Rules.propTypes = {
	disabled: PropTypes.bool,
}
