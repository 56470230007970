'use strict'

import React from 'react'
import * as globals from '../utils/globals'

export default class ContactUsPage extends React.Component {
	render() {
		var email = `mailto:${encodeURIComponent(globals.SUPPORT_EMAIL)}?subject=[BoxMan%20UI]%20Feedback/Questions`
		return (
			<div className="row">
				<div className="col-md-6 col-md-offset-3">
					<p>&nbsp;</p>
					<h2>
						<i className="fa fa-send" />
						&nbsp; Contact Us
					</h2>

					<p>Have any questions, feedback or comments? We'd love to hear about them!</p>

					<p>
						Email us at{' '}
						<a href={email} target="_blank">
							{globals.SUPPORT_EMAIL} <i className="fa fa-envelope-o" aria-hidden="true" />
						</a>
						.
					</p>

					<p>We'll do our best to respond as soon as possible.</p>
					<p>&nbsp;</p>
				</div>
			</div>
		)
	}
}
