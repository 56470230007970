'use strict'

import React from 'react'
import PropTypes from 'prop-types'

export default class Loading extends React.Component {
	constructor(props) {
		super(props)
	}

	render() {
		return (
			<div className="card">
				<div className="card-block">
					<img width={30} height={30} src="https://static.mex.cimpress.io/mcp-ux-css/1.0/assets/spinner.svg" />
					&nbsp;
					{this.props.message}
				</div>
			</div>
		)
	}
}

Loading.propTypes = {
	message: PropTypes.string,
}

Loading.defaultProps = {
	message: 'Loading, please wait..',
}
