'use strict'

import React from 'react'
import PropTypes from 'prop-types'

export default class RuleItem extends React.Component {
	onMoveUpBtnClick() {
		if (this.props.onMoveUpBtnClick) {
			this.props.onMoveUpBtnClick(this.props.rule.Index)
		}
	}

	onMoveDownBtnClick() {
		if (this.props.onMoveUpBtnClick) {
			this.props.onMoveUpBtnClick(this.props.rule.Index + 1)
		}
	}

	onEditRuleBtnClick() {
		if (this.props.onMoveUpBtnClick) {
			this.props.onEditRuleBtnClick(this.props.rule.Index)
		}
	}

	onDeleteRuleBtnClick() {
		if (this.props.onMoveUpBtnClick) {
			this.props.onDeleteRuleBtnClick(this.props.rule.Index)
		}
	}

	render() {
		var listGroupItemCssClassName = 'list-group-item'
		if (this.props.isActive) {
			listGroupItemCssClassName = 'list-group-item active'
		}

		if (this.props.rule) {
			return (
				<div className={listGroupItemCssClassName}>
					<div className="qp-table">
						<div className="qp-middle qp-rule-text">
							<span>{this.props.rule.Index === 1 ? 'When' : 'Otherwise when'}</span>
							&nbsp;
							<ins>{this.props.rule.Condition}</ins>
							&nbsp;
							<span>then shipping boxes tagged with</span>
							&nbsp;
							<code>{this.props.rule.Tag}</code>
							&nbsp;
							<span className="text-primary">
								[{this.props.numberOfBoxesWithTheSameTag}&nbsp;boxes,&nbsp;
								{this.props.numberOfActiveBoxesWithTheSameTag}&nbsp;active]
							</span>
						</div>
						<div className="qp-right">
							<button
								className="btn btn-link qp-inline-btn"
								onClick={this.onMoveUpBtnClick.bind(this)}
								disabled={this.props.isDisabled || this.props.rule.Index === 1}
								title={
									this.props.disabledReason ||
									(this.props.rule.Index === 1 ? "Can't move up the first rule" : 'Move Up')
								}
							>
								<i className="fa fa-arrow-circle-up" aria-hidden="true" />
							</button>
							&nbsp;
							<button
								className="btn btn-link qp-inline-btn"
								onClick={this.onMoveDownBtnClick.bind(this)}
								disabled={this.props.isDisabled || this.props.rule.Index === this.props.lastRuleIndex}
								title={
									this.props.disabledReason ||
									(this.props.rule.Index === this.props.lastRuleIndex ? "Can't move down the first rule" : 'Move Down')
								}
							>
								<i className="fa fa-arrow-circle-down" aria-hidden="true" />
							</button>
							&nbsp;
							<button
								className="btn btn-link qp-inline-btn"
								onClick={this.onEditRuleBtnClick.bind(this)}
								title={this.props.disabledReason || 'Modify this rule'}
								disabled={this.props.isDisabled}
							>
								<i className="fa fa-pencil-square-o" aria-hidden="true" />
							</button>
							&nbsp;
							<button
								className="btn btn-link qp-inline-btn"
								onClick={this.onDeleteRuleBtnClick.bind(this)}
								title={this.props.disabledReason || 'Delete'}
								disabled={this.props.isDisabled}
							>
								<i className="fa fa-trash" aria-hidden="true" />
							</button>
						</div>
					</div>
				</div>
			)
		}

		return <p />
	}
}

RuleItem.propTypes = {
	rule: PropTypes.object.isRequired,
	numberOfBoxesWithTheSameTag: PropTypes.number,
	numberOfActiveBoxesWithTheSameTag: PropTypes.number,
	lastRuleIndex: PropTypes.number.isRequired,
	isActive: PropTypes.bool.isRequired,
	isDisabled: PropTypes.bool.isRequired,
	disabledReason: PropTypes.string,
	onMoveUpBtnClick: PropTypes.func.isRequired,
	onEditRuleBtnClick: PropTypes.func.isRequired,
	onDeleteRuleBtnClick: PropTypes.func.isRequired,
	onMoveDownBtnClick: PropTypes.func.isRequired,
}

RuleItem.defaultProps = {
	numberOfBoxesWithTheSameTag: 0,
	numberOfActiveBoxesWithTheSameTag: 0,
}
