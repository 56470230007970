'use strict'

module.exports.setAsyncRouteLeaveHook = function (componentToLeave) {
	var router = componentToLeave.context.router
	var route = componentToLeave.props.route
	var withinHook = false
	var finalResult = null
	router.setRouteLeaveHook(route, nextLocation => {
		withinHook = true
		if (finalResult === null) {
			routerWillLeave(componentToLeave).then(result => {
				finalResult = result
				if (!withinHook && nextLocation) {
					// Re-schedule the navigation
					router.push(nextLocation)
				}
			})
		}
		var result = finalResult !== null ? finalResult : false
		withinHook = false
		finalResult = null
		return result
	})
}

module.exports.isViperLocation = function (fulfillmentLocationId) {
	return (
		fulfillmentLocationId == '1' ||
		fulfillmentLocationId == '2' ||
		fulfillmentLocationId == '3' ||
		fulfillmentLocationId == '4' ||
		fulfillmentLocationId == '5' ||
		fulfillmentLocationId == '82' ||
		fulfillmentLocationId == 'bne16129w2w' ||
		fulfillmentLocationId == 'b7bhkn35wt0' ||
		fulfillmentLocationId == 'bub46rqhtax' ||
		fulfillmentLocationId == 'bt1v62b4qvr' ||
		fulfillmentLocationId == 'b1t3h5urhqu' ||
		fulfillmentLocationId == 'bm8jhbtcwnq'
	)
}

function routerWillLeave(componentToLeave) {
	return new Promise(resolve => {
		if (!componentToLeave.containsChanges()) {
			// No unsaved changes -- leave
			resolve(true)
		} else {
			// Unsaved changes -- ask for confirmation
			componentToLeave.confirmLeave(resolve)
		}
	})
}
