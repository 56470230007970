'use strict'

import React from 'react'
import PropTypes from 'prop-types'

class HrefLink extends React.Component {
	render() {
		var icon = <i className="fa fa-external-link" aria-hidden="true" />
		if (this.props.to.slice(0, 6) == 'mailto') {
			icon = <i className="fa fa-envelope-o" aria-hidden="true" />
		}

		if (this.props.showIcon) {
			return (
				<a href={this.props.to} target="_blank">
					{this.props.title}&nbsp;{icon}
				</a>
			)
		}

		return (
			<a href={this.props.to} target="_blank">
				{this.props.title}
			</a>
		)
	}
}

HrefLink.propTypes = {
	to: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	showIcon: PropTypes.bool,
}

HrefLink.defaultProps = {
	showIcon: true,
}

export default HrefLink
