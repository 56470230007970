'use strict'

import React from 'react'
import { browserHistory } from 'react-router'
import { currencies } from 'country-data'
import Loading from './ex/Loading'
import Alert from '@cimpress/react-components/lib/Alert'
import FulfillmentLocationTitle from '../qp-components/react-fl-fi-components/FulfillmentLocationTitle'
import { Select } from '@cimpress/react-components'
import auth from './auth/auth'
import permissions from './auth/permissions'
import flLoader from '../utils/fulfillmentLocationsLoader'

export default class ConfigFulfillmentLocationPage extends React.Component {
	constructor(props) {
		super(props)

		this.canManageLocations = permissions.canManageLocations()
		this.state = {
			locations: undefined,
			fulfillmentLocationId: null,
			lengthUnit: 'cm',
			weightUnit: 'g',
			currency: 'EUR',
			fulfillmentLocations: undefined,
			loadingFulfillmentLocations_error: undefined,
		}
		this.ajaxRequests = {}
	}

	componentDidMount() {
		var fulfillmentLocationId = this.props.params.fulfillmentLocationId

		flLoader
			.loadLocationsData(auth.getAccessToken())
			.then(locations => {
				// TODO: If for some reason the code below fails - we would show false error
				this.setState({
					fulfillmentLocations: locations,
					fulfillmentLocationId: fulfillmentLocationId,
				})
			})
			.catch(errMessage => {
				this.setState({
					fulfillmentLocations: null,
					loadingFulfillmentLocations_error: errMessage,
				})
			})
	}

	componentWillUnmount() {
		Object.keys(this.ajaxRequests).forEach(ajaxRequestKey => {
			if (this.ajaxRequests[ajaxRequestKey]) {
				this.ajaxRequests[ajaxRequestKey].abort()
				this.ajaxRequests[ajaxRequestKey] = null
			}
		})
	}

	onReactSelectChangeValue(key, event) {
		var newState = JSON.parse(JSON.stringify(this.state))
		newState[key] = event ? event.value : null
		this.setState(newState)
	}

	onChangeValue(key, event) {
		var newState = JSON.parse(JSON.stringify(this.state))
		newState[key] = event ? event.target.value : null
		this.setState(newState)
	}

	onClickSetup() {
		var location = this.state.fulfillmentLocations.find(
			a => a.FulfillmentLocationId == this.state.fulfillmentLocationId,
		)

		this.setState({
			saving: true,
			error: null,
		})

		let locationConfig = {
			FulfillmentLocationId: '' + location.FulfillmentLocationId,
			FulfillmentLocationName: location.FulfillmentLocationName,
			Currency: this.state.currency,
			DefaultLengthUnit: this.state.lengthUnit,
			DefaultWeightUnit: this.state.weightUnit,
		}
		this.ajaxWithToken(
			auth.getAccessToken(),
			'settingUp',
			'POST',
			`${process.env.REACT_APP_SERVICE_URL}/api/v1/fulfillmentLocations`,
			locationConfig,
			function (response) {
				switch (response.status) {
					case 204:
						locationConfig.Description = locationConfig.FulfillmentLocationName
						localStorage.setItem('selectedLocation', JSON.stringify(locationConfig))
						browserHistory.push('/ui/shippingBoxes')

						break
					default:
						this.setState({
							saving: false,
							error: response.responseText,
						})
						break
				}
			}.bind(this),
		)
	}

	onClickCancel() {
		browserHistory.push('/ui/fulfillmentLocations/list')
	}

	validSetup() {
		return (
			this.state.fulfillmentLocationId &&
			this.state.lengthUnit &&
			this.state.weightUnit &&
			this.state.currency &&
			this.state.fulfillmentLocations
		)
	}

	render() {
		if (this.state.fulfillmentLocations === undefined) {
			return <Loading />
		}

		if (this.state.loadingFulfillmentLocations_error) {
			return <Alert message={this.state.loadingFulfillmentLocations_error} />
		}

		var selectedLocation = this.state.fulfillmentLocations.find(loc => {
			return this.state.fulfillmentLocationId === loc.FulfillmentLocationId
		})

		if (!selectedLocation) {
			return <Alert message={`Selected Location is invalid: ${selectedLocation}`} />
		}

		var optionsCurrency = currencies.all
			.filter(function (c) {
				return ['AUD', 'EUR', 'INR', 'JPY', 'USD'].indexOf(c.code) >= 0
			})
			.map(function (currency) {
				return {
					value: currency.code,
					label: `${currency.code} (${currency.name})`,
				}
			})

		var optionsLength = [
			{ value: 'm', label: 'Meters' },
			{ value: 'cm', label: 'Centimeters' },
			{ value: 'mm', label: 'Millimeters' },
			{ value: 'in', label: 'Inches' },
		]

		var optionsWeight = [
			{ value: 'g', label: 'Grams' },
			{ value: 'kg', label: 'Kilograms' },
			{ value: 'lb', label: 'Pounds' },
		]

		return (
			<div className="container">
				<h3>Configure fulfillment location</h3>
				{this.state.error ? <Alert message={this.state.error} /> : ''}
				{this.state.saving ? <Loading message="Setting up location..." /> : ''}
				<div className="card">
					<div className="card-block">
						<FulfillmentLocationTitle location={selectedLocation} />
						<div className="row">
							<div className="col-md-4">
								<Select
									label="Currency"
									disabled={this.state.saving}
									value={this.state.currency}
									options={optionsCurrency}
									onChange={this.onReactSelectChangeValue.bind(this, 'currency')}
								/>
							</div>
							<div className="col-md-4">
								<Select
									label="Default Length Unit"
									value={this.state.lengthUnit}
									onChange={this.onReactSelectChangeValue.bind(this, 'lengthUnit')}
									options={optionsLength}
								/>
							</div>
							<div className="col-md-4">
								<Select
									label="Default Weight Unit"
									disabled={this.state.saving}
									value={this.state.weightUnit}
									onChange={this.onReactSelectChangeValue.bind(this, 'weightUnit')}
									options={optionsWeight}
								/>
							</div>
						</div>
						<div className="row">
							<div className="col-md-12">
								<div className="pull-right">
									<button
										className="btn btn-default"
										disabled={this.state.saving}
										onClick={this.onClickCancel.bind(this)}
									>
										<i className="fa fa-ban-circle" />
										&nbsp;Cancel
									</button>
									&nbsp;
									<button
										className="btn btn-primary"
										disabled={this.state.saving || !this.validSetup()}
										onClick={this.onClickSetup.bind(this)}
									>
										<i className="fa fa-plus" />
										&nbsp;Setup
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}

	ajaxWithToken(token, key, method, url, data, onComplete) {
		if (this.ajaxRequests[key]) {
			this.ajaxRequests[key].abort()
			this.ajaxRequests[key] = null
		}

		const ajaxParams = {
			method: method,
			url: url,
			data: data,
			timeout: 5000,
			headers: {
				Authorization: `Bearer ${token}`,
			},
			complete: onComplete,
		}

		if (method !== 'GET') {
			ajaxParams.data = typeof data === 'string' || data instanceof String ? data : JSON.stringify(data)
			ajaxParams.dataType = 'json'
			ajaxParams.contentType = 'application/json'
		}

		this.ajaxRequests[key] = window.$.ajax(ajaxParams)
	}
}
