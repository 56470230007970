'use strict'

import React from 'react'
import PropTypes from 'prop-types'
import update from 'react-addons-update'
import { browserHistory } from 'react-router'
import TextFieldWithValidation from './ex/TextFieldWithValidation'
import validator from '../qp-components/qp-validators'
import Snackbar from '@cimpress/react-components/lib/Snackbar'
import Radio from '@cimpress/react-components/lib/Radio'
import RadioGroup from '@cimpress/react-components/lib/RadioGroup'
import Tooltip from '@cimpress/react-components/lib/Tooltip'
import auth from './auth/auth'
const validateVolume = require('../utils/InteriorVolumeValidator')

export default class ShippingBoxProperties extends React.Component {
	constructor(props) {
		super(props)
		this.analyzeProps(props, false)

		this.validators = {
			Reason: validator.validateNonEmptyString,
			Description: validator.validateNonEmptyString,
			Weight: validator.validateNonNegativeNumber,
			Cost: validator.validatePositiveNumber,
			InteriorDimension: validator.validatePositiveNumber,
			ExteriorDimension: validator.validatePositiveNumber,
			CanIncreaseBy: validator.validateNonNegativeNumber,
		}

		this.crossFieldValidators = [validateVolume]
	}

	componentWillReceiveProps(props) {
		this.analyzeProps(props, true)
	}

	analyzeProps(props, useSetState) {
		var shippingBox = props.shippingBox ? this.editShippingBox(props.shippingBox) : this.newShippingBox(props.location)

		var newState = {
			originalShippingBox: JSON.parse(JSON.stringify(shippingBox)),
			location: props.location,
			crossFieldErrors: [],
		}

		if (useSetState) {
			this.setState(newState)
		} else {
			newState.shippingBox = shippingBox
			this.state = newState
		}
	}

	onChangeValue(key, event, value) {
		var newState = update(this.state, {
			shippingBox: {
				[key]: {
					$set: key === 'Active' ? value : event.target.value,
				},
			},
		})
		this.setState(newState)
	}

	containsChanges() {
		return JSON.stringify(this.state.originalShippingBox) != JSON.stringify(this.state.shippingBox)
	}

	cancel() {
		browserHistory.push('/ui/shippingBoxes')
	}

	isShippingBoxValid() {
		var errors = []
		errors = errors.concat(this.validators['Description'](this.state.shippingBox.Description))
		errors = errors.concat(this.validators['Cost'](this.state.shippingBox.Cost))
		errors = errors.concat(this.validators['Weight'](this.state.shippingBox.Weight))
		errors = errors.concat(this.validators['Weight'](this.state.shippingBox.EstimatedMaxHoldWeight))
		errors = errors.concat(this.validators['ExteriorDimension'](this.state.shippingBox.ExteriorDimensions[0]))
		errors = errors.concat(this.validators['ExteriorDimension'](this.state.shippingBox.ExteriorDimensions[1]))
		errors = errors.concat(this.validators['ExteriorDimension'](this.state.shippingBox.ExteriorDimensions[2]))
		errors = errors.concat(this.validators['InteriorDimension'](this.state.shippingBox.InteriorDimensions[0]))
		errors = errors.concat(this.validators['InteriorDimension'](this.state.shippingBox.InteriorDimensions[1]))
		errors = errors.concat(this.validators['InteriorDimension'](this.state.shippingBox.InteriorDimensions[2]))
		errors = errors.concat(this.validators['CanIncreaseBy'](this.state.shippingBox.DimensionsCanIncreaseBy[0]))
		errors = errors.concat(this.validators['CanIncreaseBy'](this.state.shippingBox.DimensionsCanIncreaseBy[1]))
		errors = errors.concat(this.validators['CanIncreaseBy'](this.state.shippingBox.DimensionsCanIncreaseBy[2]))

		this.crossFieldValidators.forEach(validator => {
			errors = errors.concat(validator(this.state.shippingBox))
		})

		return errors.length === 0
	}

	save() {
		if (this.state.shippingBox.ShippingBoxId) {
			this.updateShippingBox()
		} else {
			this.insertShippingBox()
		}
	}

	insertShippingBox() {
		this.setState({ isSaving: true })
		const token = auth.getAccessToken()
		this.serverRequest = window.$.ajax({
			type: 'POST',
			url: `${process.env.REACT_APP_SERVICE_URL}/api/v1/shippingBoxes`,
			timeout: 10000,
			data: JSON.stringify(this.toAPIdata(this.state.shippingBox)),
			contentType: 'application/json',
			dataType: 'json',
			headers: {
				Authorization: `Bearer ${token}`,
			},

			success: function () {
				this.setState({
					originalShippingBox: this.state.shippingBox,
					isSaving: false,
				})
				browserHistory.push('/ui/shippingBoxes/#' + this.state.location.FulfillmentLocationId)
			}.bind(this),

			error: function (error) {
				this.setState(
					Object.assign(
						{},
						{
							isSaving: false,
						},
						this.stateToShowSnackBar(this.formatErrorMessage(error)),
					),
				)
			}.bind(this),
		})
	}

	updateShippingBox() {
		this.setState({ isSaving: true })
		const token = auth.getAccessToken()
		this.serverRequest = window.$.ajax({
			type: 'PUT',
			url: `${process.env.REACT_APP_SERVICE_URL}/api/v1/shippingBoxes/${this.state.shippingBox.ShippingBoxId}`,
			timeout: 10000,
			data: JSON.stringify(this.toAPIdata(this.state.shippingBox)),
			contentType: 'application/json',
			dataType: 'json',
			headers: {
				Authorization: `Bearer ${token}`,
			},

			success: function () {
				this.setState({
					originalShippingBox: this.state.shippingBox,
					isSaving: false,
				})
				browserHistory.push('/ui/shippingBoxes')
			}.bind(this),

			error: function (error) {
				this.setState(
					Object.assign(
						{},
						{
							isSaving: false,
						},
						this.stateToShowSnackBar(this.formatErrorMessage(error)),
					),
				)
			}.bind(this),
		})
	}

	formatErrorMessage(error) {
		if (error.responseJSON) {
			return error.responseJSON.results.errors.map(function (each) {
				return each.code + ': ' + each.description + '(' + each.message + ')<br/><br/>'
			})
		} else if (error.responseText) {
			return error.responseText
		}
		return error
	}

	newShippingBox(location) {
		return {
			Active: false,
			Cost: null,
			Description: null,
			EstimatedMaxHoldWeight: null,
			Weight: null,
			ExteriorDimensions: [null, null, null],
			InteriorDimensions: [null, null, null],
			DimensionsCanIncreaseBy: [null, null, null],
			WeightUnits: location.DefaultWeightUnit,
			LengthUnits: location.DefaultLengthUnit,
		}
	}

	editShippingBox(shippingBox) {
		return {
			ShippingBoxId: shippingBox.ShippingBoxId,
			Active: shippingBox.Active,
			Cost: shippingBox.Cost,
			Description: shippingBox.Description,
			EstimatedMaxHoldWeight: this.trimUnits(shippingBox.EstimatedMaxHoldWeight),
			Weight: this.trimUnits(shippingBox.Weight),
			ExteriorDimensions: shippingBox.ExteriorDimensions.slice(0).map(this.trimUnits),
			InteriorDimensions: shippingBox.InteriorDimensions.slice(0).map(this.trimUnits),
			DimensionsCanIncreaseBy: shippingBox.DimensionsCanIncreaseBy.slice(0).map(this.trimUnits),
			Reason: null,
			WeightUnits: this.trimNumbers(shippingBox.Weight),
			LengthUnits: this.trimNumbers(shippingBox.ExteriorDimensions[0]),
		}
	}

	toAPIdata(shippingBox) {
		return {
			ShippingBoxId: shippingBox.ShippingBoxId,
			Active: shippingBox.Active,
			Cost: shippingBox.Cost,
			Description: shippingBox.Description,
			EstimatedMaxHoldWeight: shippingBox.EstimatedMaxHoldWeight + ' ' + shippingBox.WeightUnits,
			Weight: shippingBox.Weight + ' ' + shippingBox.WeightUnits,
			ExteriorDimensions: this.addUnits(shippingBox.ExteriorDimensions.slice(0), shippingBox.LengthUnits),
			InteriorDimensions: this.addUnits(shippingBox.InteriorDimensions.slice(0), shippingBox.LengthUnits),
			DimensionsCanIncreaseBy: this.addUnits(shippingBox.DimensionsCanIncreaseBy.slice(0), shippingBox.LengthUnits),
			Reason: shippingBox.Reason,
			FulfillmentLocationId: this.state.location.FulfillmentLocationId,
		}
	}

	addUnits(arr, units) {
		return arr.map(function (each) {
			return each + ' ' + units
		})
	}

	trimUnits(each) {
		return each.replace(/[^\d.-]/g, '')
	}

	trimNumbers(each) {
		return each.replace(/[\d.\-\s]/g, '')
	}

	onChangeKeyValue(key, value) {
		const shippingBox = JSON.parse(JSON.stringify(this.state.shippingBox))

		const parts = key.split('_')
		if (parts.length === 2) {
			let index = parseInt(parts[1])
			let realKey = parts[0]
			shippingBox[realKey][index] = value
		} else {
			shippingBox[key] = value
		}

		let crossFieldErrors = []
		this.crossFieldValidators.forEach(validator => {
			crossFieldErrors = crossFieldErrors.concat(validator(shippingBox))
		})

		this.setState({
			shippingBox: shippingBox,
			crossFieldErrors: crossFieldErrors,
		})
	}

	stateToShowSnackBar(message, type = 'danger') {
		return {
			snackBarShow: true,
			snackBarType: type,
			snackBarBody: message,
		}
	}

	onHideSnackbar() {
		this.setState({
			snackBarShow: false,
		})
	}

	renderTooltip(tip, content) {
		return (
			<Tooltip contents={tip}>
				<i className="fa fa-question-circle" aria-hidden="true" />
				&nbsp;
				{content}
			</Tooltip>
		)
	}

	findErrorMessage(fieldName, defaultError) {
		for (let i = 0; i < this.state.crossFieldErrors.length; i++) {
			let error = this.state.crossFieldErrors[i]
			if (error.fields.includes(fieldName)) {
				return error.message
			}
		}

		return defaultError
	}

	isViolatedCrossFieldValidation(fieldName) {
		for (let i = 0; i < this.state.crossFieldErrors.length; i++) {
			let error = this.state.crossFieldErrors[i]
			if (error.fields.includes(fieldName)) {
				return true
			}
		}

		return false
	}

	render() {
		return (
			<div className="qp-margin-bottom">
				<Snackbar
					show={this.state.snackBarShow || false}
					onHideSnackbar={this.onHideSnackbar.bind(this)}
					bsStyle={this.state.snackBarType || 'danger'}
					delay="8000"
				>
					{this.state.snackBarBody}
				</Snackbar>

				<div className="row">
					<div className="col-md-2">
						<div className="qp-radio-group">
							<RadioGroup
								disabled={this.props.disabled}
								name="state"
								label="State"
								valueSelected={this.state.shippingBox.Active}
								onChange={this.onChangeValue.bind(this, 'Active')}
							>
								<Radio disabled={this.props.disabled} label="Inactive" value={false} />
								<Radio disabled={this.props.disabled} label="Active" value={true} />
							</RadioGroup>
						</div>
					</div>
					<div className="col-md-5">
						<TextFieldWithValidation
							value={this.state.shippingBox.Description}
							label={this.renderTooltip('The name of the box for easier management.', 'Name')}
							handleValueChange={this.onChangeKeyValue.bind(this, 'Description')}
							name="description"
							helpMessage="&nbsp;"
							errorMessage="Please enter shipping box description"
							validator={this.validators['Description']}
							disabled={this.props.disabled}
						/>
					</div>
					<div className="col-md-2">
						<TextFieldWithValidation
							value={this.state.shippingBox.Cost}
							label={this.renderTooltip('Cost of the box.', `Cost (${this.state.location.Currency})`)}
							handleValueChange={this.onChangeKeyValue.bind(this, 'Cost')}
							name="cost"
							helpMessage="&nbsp;"
							errorMessage="The cost must be a positive value"
							validator={this.validators['Cost']}
							disabled={this.props.disabled}
						/>
					</div>
				</div>

				<div className="row">
					<div className="col-md-3">
						<TextFieldWithValidation
							value={this.state.shippingBox.Weight}
							label={this.renderTooltip(
								'Weight of the box when empty.',
								`Weight (${this.state.shippingBox.WeightUnits})`,
							)}
							handleValueChange={this.onChangeKeyValue.bind(this, 'Weight')}
							name="weight"
							helpMessage="&nbsp;"
							errorMessage="The weight must be a positive value"
							validator={this.validators['Weight']}
							disabled={this.props.disabled}
						/>
					</div>
					<div className="col-md-3">
						<TextFieldWithValidation
							value={this.state.shippingBox.EstimatedMaxHoldWeight}
							label={this.renderTooltip(
								'Maximum weight that can be put in the box.',
								`Max Hold (${this.state.shippingBox.WeightUnits})`,
							)}
							handleValueChange={this.onChangeKeyValue.bind(this, 'EstimatedMaxHoldWeight')}
							name="estimatedMaxHoldWeight"
							helpMessage="&nbsp;"
							errorMessage="Must be a positive number"
							validator={this.validators['Weight']}
							disabled={this.props.disabled}
						/>
					</div>
					<div className="col-md-4">&nbsp;</div>
				</div>

				<div className="row">
					<div className="col-md-3 qp-dimension-field">
						<TextFieldWithValidation
							value={this.state.shippingBox.InteriorDimensions[0]}
							label={'Interior dimension #1 (' + this.state.shippingBox.LengthUnits + ')'}
							handleValueChange={this.onChangeKeyValue.bind(this, 'InteriorDimensions_0')}
							name="interiorDimensions0"
							tooltip="The interior dimensions control how much items the box can hold."
							helpMessage="&nbsp;"
							errorMessage={this.findErrorMessage('interiorDimensions0', 'Must be a positive number')}
							validator={this.validators['InteriorDimension']}
							disabled={this.props.disabled}
							crossFieldError={this.isViolatedCrossFieldValidation('interiorDimensions0')}
						/>
					</div>
					<div className="col-md-3 qp-dimension-field">
						<TextFieldWithValidation
							value={this.state.shippingBox.InteriorDimensions[1]}
							label={'Interior dimension #2 (' + this.state.shippingBox.LengthUnits + ')'}
							handleValueChange={this.onChangeKeyValue.bind(this, 'InteriorDimensions_1')}
							name="interiorDimensions1"
							tooltip="The interior dimensions control how much items the box can hold."
							helpMessage="&nbsp;"
							errorMessage={this.findErrorMessage('interiorDimensions1', 'Must be a positive number')}
							validator={this.validators['InteriorDimension']}
							disabled={this.props.disabled}
							crossFieldError={this.isViolatedCrossFieldValidation('interiorDimensions1')}
						/>
					</div>
					<div className="col-md-3">
						<TextFieldWithValidation
							value={this.state.shippingBox.InteriorDimensions[2]}
							label={'Interior dimension #3 (' + this.state.shippingBox.LengthUnits + ')'}
							handleValueChange={this.onChangeKeyValue.bind(this, 'InteriorDimensions_2')}
							name="interiorDimensions2"
							tooltip="The interior dimensions control how much items the box can hold."
							helpMessage="&nbsp;"
							errorMessage={this.findErrorMessage('interiorDimensions2', 'Must be a positive number')}
							validator={this.validators['InteriorDimension']}
							disabled={this.props.disabled}
							crossFieldError={this.isViolatedCrossFieldValidation('interiorDimensions2')}
						/>
					</div>
					<div className="col-md-2">
						<div className="qp-info-sign text-primary">
							<Tooltip contents={'The interior dimensions control how much items the box can hold.'}>
								<i className="fa fa-question-circle" aria-hidden="true" />
								<img src="/images/interior.png" width="49" height="32" />
							</Tooltip>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-md-3 qp-dimension-field">
						<TextFieldWithValidation
							value={this.state.shippingBox.ExteriorDimensions[0]}
							label={'Exterior dimension #1 (' + this.state.shippingBox.LengthUnits + ')'}
							handleValueChange={this.onChangeKeyValue.bind(this, 'ExteriorDimensions_0')}
							name="exteriorDimensions0"
							tooltip="The exterior dimensions are used for shipping purposes."
							helpMessage="&nbsp;"
							errorMessage={this.findErrorMessage('exteriorDimensions0', 'Must be a positive number')}
							validator={this.validators['ExteriorDimension']}
							disabled={this.props.disabled}
							crossFieldError={this.isViolatedCrossFieldValidation('exteriorDimensions0')}
						/>
					</div>
					<div className="col-md-3 qp-dimension-field">
						<TextFieldWithValidation
							value={this.state.shippingBox.ExteriorDimensions[1]}
							label={'Exterior dimension #2 (' + this.state.shippingBox.LengthUnits + ')'}
							handleValueChange={this.onChangeKeyValue.bind(this, 'ExteriorDimensions_1')}
							name="exteriorDimensions1"
							tooltip="The exterior dimensions are used for shipping purposes."
							helpMessage="&nbsp;"
							errorMessage={this.findErrorMessage('exteriorDimensions1', 'Must be a positive number')}
							validator={this.validators['ExteriorDimension']}
							disabled={this.props.disabled}
							crossFieldError={this.isViolatedCrossFieldValidation('exteriorDimensions1')}
						/>
					</div>
					<div className="col-md-3">
						<TextFieldWithValidation
							value={this.state.shippingBox.ExteriorDimensions[2]}
							label={'Exterior dimension #3 (' + this.state.shippingBox.LengthUnits + ')'}
							handleValueChange={this.onChangeKeyValue.bind(this, 'ExteriorDimensions_2')}
							name="exteriorDimensions2"
							tooltip="The exterior dimensions are used for shipping purposes."
							helpMessage="&nbsp;"
							errorMessage={this.findErrorMessage('exteriorDimensions2', 'Must be a positive number')}
							validator={this.validators['ExteriorDimension']}
							disabled={this.props.disabled}
							crossFieldError={this.isViolatedCrossFieldValidation('exteriorDimensions2')}
						/>
					</div>
					<div className="col-md-2">
						<div className="qp-info-sign text-primary">
							<Tooltip contents={'The exterior dimensions are used for shipping purposes.'}>
								<i className="fa fa-question-circle" aria-hidden="true" />
								<img src="/images/exterior.png" width="49" height="32" />
							</Tooltip>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-md-3 qp-dimension-field">
						<TextFieldWithValidation
							value={this.state.shippingBox.DimensionsCanIncreaseBy[0]}
							label={'Dimension #1 can increase by (' + this.state.shippingBox.LengthUnits + ')'}
							handleValueChange={this.onChangeKeyValue.bind(this, 'DimensionsCanIncreaseBy_0')}
							name="dimensionsCanIncreaseBy0"
							tooltip="How much each dimension can vary, or how 'flexible' is the box."
							helpMessage="&nbsp;"
							errorMessage="Must be a non-negative number"
							validator={this.validators['CanIncreaseBy']}
							disabled={this.props.disabled}
						/>
					</div>
					<div className="col-md-3 qp-dimension-field">
						<TextFieldWithValidation
							value={this.state.shippingBox.DimensionsCanIncreaseBy[1]}
							label={'Dimension #2 can increase by (' + this.state.shippingBox.LengthUnits + ')'}
							handleValueChange={this.onChangeKeyValue.bind(this, 'DimensionsCanIncreaseBy_1')}
							name="dimensionsCanIncreaseBy1"
							tooltip="How much each dimension can vary, or how 'flexible' is the box."
							helpMessage="&nbsp;"
							errorMessage="Must be a non-negative number"
							validator={this.validators['CanIncreaseBy']}
							disabled={this.props.disabled}
						/>
					</div>
					<div className="col-md-3">
						<TextFieldWithValidation
							value={this.state.shippingBox.DimensionsCanIncreaseBy[2]}
							label={'Dimension #3 can increase by (' + this.state.shippingBox.LengthUnits + ')'}
							handleValueChange={this.onChangeKeyValue.bind(this, 'DimensionsCanIncreaseBy_2')}
							name="dimensionsCanIncreaseBy2"
							tooltip="How much each dimension can vary, or how 'flexible' is the box."
							helpMessage="&nbsp;"
							errorMessage="Must be a non-negative number"
							validator={this.validators['CanIncreaseBy']}
							disabled={this.props.disabled}
						/>
					</div>
					<div className="col-md-2">
						<div className="qp-info-sign text-primary">
							<Tooltip contents={"How much each dimension can vary, or how 'flexible' is the box."}>
								<i className="fa fa-question-circle" aria-hidden="true" />
								<img src="/images/flexible.png" width="49" height="32" />
							</Tooltip>
						</div>
					</div>
				</div>

				<div className="row">
					<div className="col-md-12">
						<div
							style={{
								display: this.state.shippingBox.ShippingBoxId ? 'block' : 'none',
							}}
						>
							<TextFieldWithValidation
								value={this.state.shippingBox.Reason}
								label={'Reason'}
								handleValueChange={this.onChangeKeyValue.bind(this, 'Reason')}
								name="reason"
								tooltip="For flexible boxes, how much each dimension can vary."
								helpMessage="&nbsp;"
								errorMessage="Please provide a reason for this change."
								validator={this.validators['Reason']}
								disabled={this.props.disabled}
							/>
						</div>
					</div>
				</div>

				<div>
					&nbsp;&nbsp;
					<div className="pull-right">
						<button className="btn btn-default" disabled={this.state.isSaving} onClick={this.cancel.bind(this)}>
							<i className="fa fa-remove" />
							&nbsp;Cancel
						</button>
						&nbsp;&nbsp;
						<button
							className="btn btn-primary"
							disabled={
								this.props.disabled ||
								this.state.isSaving ||
								!this.isShippingBoxValid() ||
								(this.state.shippingBox.ShippingBoxId && !this.state.shippingBox.Reason)
							}
							onClick={!this.state.isSaving ? this.save.bind(this) : null}
						>
							<i className="fa fa-floppy-o" />
							&nbsp;Save
						</button>
					</div>
				</div>
			</div>
		)
	}
}

ShippingBoxProperties.propTypes = {
	disabled: PropTypes.bool,
}
