import React from 'react'
import HrefLink from '../ex/HrefLink'
import MailUsLink from '../ex/MailUsLink'

export default class HelpSectionShippingBoxProperties extends React.Component {
	render() {
		return (
			<div>
				<h3>Introduction</h3>
				<p>
					The Shipping Box Management Service allows users to manage shipping boxes that are available at a particular
					fulfillment location.
				</p>

				<div>
					<p>Use cases:</p>
					<ul>
						<li>Add a shipping box</li>
						<li>Update the properties of a shipping box (including dimensions, whether the box is enabled, etc.)</li>
						<li>Update rules around when the shipping box should be made eligible to be selected by a shipping plan</li>
						<li>View the history of changes on a particular shipping box (what changed, when, by whom)</li>
					</ul>
				</div>

				<p>
					For more information, please visit the{' '}
					<HrefLink
						to="https://corewiki.cimpress.net/wiki/Shipping_Box_Management_Service"
						title="Shipping Box Management Service wiki page"
					/>
					. If you have any questions, please reach out to <MailUsLink subject={'[BoxMan UI question]'} />.
				</p>

				<h3>Shipping Box Core Properties</h3>

				<p>
					<strong>What does the Active/Inactive field refer to?</strong>
				</p>
				<p>
					Setting a shipping box to Active status indicates that the shipping box is eligible for the shipping box
					selection algorithm. On the other hand, setting, the shipping box to Inactive status indicates that it is
					ineligible for selection.
				</p>

				<p>
					<strong>What does Max Hold refer to?</strong>
				</p>
				<p>Max Hold refers to the estimated amount of weight a shipping box can hold.</p>

				<p>
					<strong>Why are there three values for Interior, Exterior, and Can Increase By?</strong>
				</p>
				<p>
					Each value represents a dimension of the shipping box. The values are meant to be ordered from smallest value
					to largest. The first value of Interior should also correspond with the first value of Exterior and Can
					Increase By.
				</p>

				<p>
					<strong>What does Can Increase By mean?</strong>
				</p>
				<p>
					This value reflects the flexibility of a shipping box dimension. Setting the flexibility to 0 indicates that a
					particular dimension is not flexible. An example of a flexible shipping box is an envelope, where the width
					changes based on the contents.
				</p>

				<h3>Viper Shipping Box Data Synchronization</h3>
				<p>
					In Viper, shipping box data is generally reloaded every 5 minutes, so changes made in the Shipping Box
					Management Service should be visible in Viper after that interval. However, some screens may require a manual
					refresh to retrieve and use the latest shipping box information.
				</p>
			</div>
		)
	}
}
