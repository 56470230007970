'use strict'

import React from 'react'
import PropTypes from 'prop-types'
import { browserHistory, Link } from 'react-router'
import update from 'react-addons-update'
import ProgressBar from './ex/ProgressBar'
import MultipleShippingBoxSelection from './MultipleShippingBoxSelection'
import Utils from '../utils/Utils'
import ConfirmBox from './ex/ConfirmBox'
import TextFieldWithValidation from './ex/TextFieldWithValidation'
import Loading from './ex/Loading'
import validator from '../qp-components/qp-validators'
import boxValidator from '../utils/boxValidator'
import quantities from 'js-quantities'
import auth from './auth/auth'
import Snackbar from '@cimpress/react-components/lib/Snackbar'

export default class MultiBoxTagsAdd extends React.Component {
	constructor(props) {
		super(props)
		this.analyzeProps(props, false)
	}

	componentWillReceiveProps(props) {
		this.analyzeProps(props, true)
	}

	analyzeProps(_props, useSetState) {
		var newState = {
			invalid: {
				tagActionRowKey: false,
			},
			addTagKey: null,
			addTagValue: null,
			reason: null,
			savedShippingBoxes: [],
			progressDetails: [],
		}

		if (useSetState) {
			this.setState(newState)
		} else {
			this.state = newState
		}
	}

	componentDidMount() {
		var selectedLocation = localStorage.getItem('selectedLocation')
		var location = null
		if (selectedLocation) {
			location = JSON.parse(selectedLocation)
		}

		if (!location) {
			browserHistory.push('/ui/fulfillmentLocations')
			return
		}

		this.setState({ location: location })
		this.getShippingBoxes(location.FulfillmentLocationId)

		Utils.setAsyncRouteLeaveHook(this)
	}

	getShippingBoxes(locationCode) {
		const token = auth.getAccessToken()
		this.serverRequest = window.$.ajax({
			method: 'GET',
			url: `${process.env.REACT_APP_SERVICE_URL}/api/v1/shippingBoxes?fulfillmentLocationId=${locationCode}`,
			timeout: 3000,

			headers: {
				Authorization: `Bearer ${token}`,
			},

			success: function (shippingBoxesData) {
				this.setState({
					shippingBoxes: shippingBoxesData.sort(function (a, b) {
						return a.Description.localeCompare(b.Description)
					}),
				})
				this.forceUpdate()
			}.bind(this),

			error: function (_error, _status, message) {
				this.setState({
					shippingBoxes: null,
					error: message,
				})
			}.bind(this),
		})
	}

	onHideSnackbar() {
		this.setState({
			snackBarShow: false,
		})
	}

	addTagsToBoxes() {
		const key = this.state.addTagKey.trim().toLowerCase()
		let value = this.state.addTagValue
		let validationErrors = boxValidator.validateViperShippingBoxTagKey(key, value)
		if (validationErrors.length > 0) {
			this.setState(
				Object.assign(
					{},
					{
						snackBarShow: true,
						snackBarBody: `Invalid value for tag key ${key}. Error: ${validationErrors}`,
					},
				),
			)
			return
		}

		if (
			key === 'viper:closingdeviceminweight' ||
			key === 'viper:singleitemweight' ||
			key === 'viper:minaccumweight' ||
			key === 'viper:maxaccumweight'
		) {
			// Viper doesn't accept measurements without spaces between the value and unit as valid
			// e.g. "1lb" is invalid but "1 lb" is valid
			const quantity = quantities(value)
			value = quantity.toString()
			if (value.indexOf('lbs') >= 0) {
				value = value.slice(0, value.length - 1)
			}
		}

		this.setState({ isSaving: true })

		this.serverRequest = {}
		const token = auth.getAccessToken()
		var reason = this.state.reason

		var self = this
		var selectedBoxes = this.multiselect.currentSelection()
		selectedBoxes.map(function (thisBox) {
			var tagData = {
				ShippingBoxId: thisBox.ShippingBoxId,
				Key: key,
				Value: value,
				Reason: reason,
			}

			self.serverRequest[thisBox.ShippingBoxId] = window.$.ajax({
				type: 'POST',
				url: `${process.env.REACT_APP_SERVICE_URL}/api/v1/shippingBoxTag`,
				timeout: 10000,
				data: JSON.stringify(tagData),
				contentType: 'application/json',
				dataType: 'json',
				headers: {
					Authorization: `Bearer ${token}`,
				},

				success: function () {
					var savedAlready = this.state.savedShippingBoxes
					savedAlready[thisBox.ShippingBoxId] = thisBox

					var progressDetails = this.state.progressDetails
					var message = "Adding tag to '" + thisBox.Description + "' succeed."
					progressDetails.push(
						<span>
							<span className="text-success">
								<i className="fa fa-check" aria-hidden="true" />
							</span>
							&nbsp;
							{message}
						</span>,
					)

					this.setState({
						savedShippingBoxes: savedAlready,
						progressDetails: progressDetails,
					})
				}.bind(self),

				error: function (error) {
					var savedAlready = this.state.savedShippingBoxes
					savedAlready[thisBox.ShippingBoxId] = error

					var progressDetails = this.state.progressDetails
					var message = "Adding tag to '" + thisBox.Description + "' failed: " + this.formatErrorMessage(error)
					progressDetails.push(
						<span>
							<span className="text-danger">
								<i className="fa fa-times" aria-hidden="true" />
							</span>
							&nbsp;
							{message}
						</span>,
					)

					this.setState({
						savedShippingBoxes: savedAlready,
						progressDetails: progressDetails,
					})
				}.bind(self),
			})
		})
	}

	formatErrorMessage(error) {
		if (error.responseJSON) {
			return error.responseJSON.results.errors.map(function (each) {
				return each.code + ': ' + each.description + '(' + each.message + ')<br/><br/>'
			})
		} else if (error.responseText) {
			return error.responseText
		}
		return error
	}

	handleValueChange(key, value) {
		var newState = update(this.state, {
			[key]: {
				$set: value,
			},
		})
		this.setState(newState)
	}

	containsChanges() {
		return this.state.selectedBoxes && this.state.selectedBoxes.length > 0
	}

	acknowledgeSaved() {
		this.getShippingBoxes(this.state.location.FulfillmentLocationId)
		this.setState({
			isSaving: false,
			progressDetails: [],
			selectedBoxes: [],
			savedShippingBoxes: {},
			addTagKey: '',
			addTagValue: '',
			reason: '',
		})
	}

	cancel() {
		browserHistory.push('/ui/shippingBoxes')
	}

	onSelectionChanged(boxes, selected) {
		this.setState({
			shippingBoxes: boxes,
			selectedBoxes: selected,
		})
	}

	renderAddTags() {
		var shippingBoxes = this.state.shippingBoxes
		var selectedBoxes = this.state.selectedBoxes

		return (
			<div className="qp-margin-bottom">
				<TextFieldWithValidation
					value={this.state.addTagKey}
					label="Tag Key"
					handleValueChange={this.handleValueChange.bind(this, 'addTagKey')}
					name="addTagKey"
					helpMessage="Please enter shipping box tag key"
					errorMessage="Please enter shipping box tag key"
					validator={boxValidator.validateShippingBoxTagKey}
					disabled={this.state.isSaving}
				/>
				<TextFieldWithValidation
					value={this.state.addTagValue}
					label="Tag Value"
					handleValueChange={this.handleValueChange.bind(this, 'addTagValue')}
					name="addTagValue"
					helpMessage="Please enter shipping box tag value"
					errorMessage="Please enter shipping box tag value"
					disabled={this.state.isSaving}
				/>
				<MultipleShippingBoxSelection
					ref={c => {
						this.multiselect = c
					}}
					boxesToSelectFrom={shippingBoxes}
					boxesToSelectFromCaption="Select boxes to tag:"
					selectedShippingBoxes={selectedBoxes}
					selectedShippingBoxesCaption="Selected boxes"
					onSelectionChanged={this.onSelectionChanged.bind(this)}
					disabled={this.state.isSaving}
				/>
			</div>
		)
	}

	renderReason(selectedBoxes) {
		if (this.state.isSaving) {
			return
		}

		return (
			<div>
				<TextFieldWithValidation
					value={this.state.reason}
					label="Change Reason"
					handleValueChange={this.handleValueChange.bind(this, 'reason')}
					name="reason"
					helpMessage="Please provide a reason for this change."
					errorMessage="Please provide a reason for this change."
					validator={validator.validateNonEmptyString}
				/>

				<div className="pull-right">
					<button className="btn btn-default" onClick={this.cancel.bind(this)}>
						<i className="fa fa-ban" aria-hidden="true" />
						&nbsp;Cancel
					</button>
					&nbsp;&nbsp;
					<button
						className="btn btn-primary"
						onClick={this.addTagsToBoxes.bind(this)}
						disabled={this.state.isSaving || selectedBoxes.length == 0 || !this.state.addTagKey || !this.state.reason}
					>
						<i className="fa fa-floppy-o" aria-hidden="true" />
						&nbsp;Add tags
					</button>
				</div>
			</div>
		)
	}

	stateToHideConfirmBox() {
		return {
			confirmBoxShow: false,
			confirmBoxOnCancel: this.onConfirmBoxCancel.bind(this),
		}
	}

	onConfirmBoxCancel() {
		this.setState(this.stateToHideConfirmBox())
	}

	onLeaveConfirmed() {
		this.setState(this.stateToHideConfirmBox())
		this.state.leaveCallback(true)
	}

	onLeaveCancelled() {
		this.setState(this.stateToHideConfirmBox())
		this.state.leaveCallback(false)
	}

	confirmLeave(callback) {
		this.setState({
			leaveCallback: callback,
			confirmBoxShow: true,
			confirmBoxTitle: 'Leave confirmation',
			confirmBoxBody: 'There are unsaved changes. Would you like to navigate out of this page and lose them?',
			confirmBoxOnConfirm: this.onLeaveConfirmed.bind(this),
			confirmBoxOnCancel: this.onLeaveCancelled.bind(this),
		})
	}

	render() {
		if (!this.state || !this.state.shippingBoxes) {
			return <Loading />
		}

		var selectedBoxes = this.state.selectedBoxes ? this.state.selectedBoxes : []

		return (
			<div>
				<Snackbar
					show={this.state.snackBarShow || false}
					onHideSnackbar={this.onHideSnackbar.bind(this)}
					bsStyle="danger"
					delay="8000"
				>
					{this.state.snackBarBody}
				</Snackbar>

				<ol className="breadcrumb">
					<li>{this.state.location.Description}</li>
					<li>
						<Link to={'/ui/shippingBoxes/'}>Shipping Boxes</Link>
					</li>
					<li className="active">Add tags to multiple boxes</li>
				</ol>

				<ConfirmBox
					show={this.state.confirmBoxShow}
					title={this.state.confirmBoxTitle}
					body={this.state.confirmBoxBody}
					onCancel={this.state.confirmBoxOnCancel || this.onConfirmBoxCancel.bind(this)}
					onConfirm={this.state.confirmBoxOnConfirm ? this.state.confirmBoxOnConfirm : null}
				/>

				<div className="card">
					<div className="card-block">
						{this.renderAddTags()}

						<ProgressBar
							visible={this.state.isSaving}
							progressDetails={this.state.progressDetails}
							currentValue={Object.keys(this.state.savedShippingBoxes).length}
							maxValue={selectedBoxes.length}
							hasError={this.state.errorSaving}
							onFinishButtonClick={this.acknowledgeSaved.bind(this)}
							finishButtonCaption="Ok, thanks!"
						/>

						{this.renderReason(selectedBoxes)}
					</div>
				</div>
			</div>
		)
	}
}

MultiBoxTagsAdd.contextTypes = {
	router: PropTypes.object.isRequired,
}
