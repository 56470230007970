'use strict'

import React from 'react'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'

import Loading from './ex/Loading'

export default class MultipleShippingBoxSelection extends React.Component {
	constructor(props) {
		super(props)
		this.analyzeProps(props, false)
	}

	componentWillReceiveProps(props) {
		this.analyzeProps(props, true)
	}

	analyzeProps(props, useSetState) {
		var newState = {
			boxesToSelectFrom: props.boxesToSelectFrom,
			boxesToSelectFromCaption: props.boxesToSelectFromCaption,
			selectedShippingBoxes: props.selectedShippingBoxes ? props.selectedShippingBoxes : [],
			selectedShippingBoxesCaption: props.selectedShippingBoxesCaption,
			descriptionFormatter: props.descriptionFormatter,
			onSelectionChanged: props.onSelectionChanged,
			disabled: props.disabled,
		}

		if (useSetState) {
			this.setState(newState)
		} else {
			this.state = newState
		}
	}

	selectShippingBox(shippingBoxId) {
		var selected = this.state.selectedShippingBoxes ? this.state.selectedShippingBoxes.slice() : []
		var boxes = this.state.boxesToSelectFrom.filter(function (b) {
			if (b.ShippingBoxId == shippingBoxId) {
				selected.push(b)
				return false
			}
			return true
		})

		if (this.state.onSelectionChanged) {
			this.state.onSelectionChanged(boxes, selected)
		}
	}

	deselectShippingBox(shippingBoxId) {
		var boxes = this.state.boxesToSelectFrom
		var x = this.state.selectedShippingBoxes ? this.state.selectedShippingBoxes.slice() : []
		var selected = x.filter(function (b) {
			if (b.ShippingBoxId == shippingBoxId) {
				boxes.push(b)
				return false
			}
			return true
		})

		if (this.state.onSelectionChanged) {
			this.state.onSelectionChanged(boxes, selected)
		}
	}

	currentSelection() {
		return JSON.parse(JSON.stringify(this.state.selectedShippingBoxes ? this.state.selectedShippingBoxes : []))
	}

	render() {
		if (!this.state || !this.state.boxesToSelectFrom) {
			return <Loading />
		}

		var self = this

		function selectBox(cell, row) {
			if (self.state.disabled) {
				return cell
			}

			var caption = cell
			if (self.state.descriptionFormatter) {
				caption = self.state.descriptionFormatter(row)
			}

			return (
				<a className="qp-pointer" onClick={self.selectShippingBox.bind(self, row.ShippingBoxId)}>
					{caption}
				</a>
			)
		}

		function deselectBox(cell, row) {
			if (self.state.disabled) {
				return cell
			}

			var caption = cell
			if (self.state.descriptionFormatter) {
				caption = self.state.descriptionFormatter(row)
			}

			return (
				<a className="qp-pointer" onClick={self.deselectShippingBox.bind(self, row.ShippingBoxId)}>
					{caption}
				</a>
			)
		}

		return (
			<div className="row">
				<div className="col-md-6">
					<BootstrapTable data={this.state.boxesToSelectFrom} search={true} hover={true} height="300">
						<TableHeaderColumn
							width="60"
							dataField="ShippingBoxId"
							dataAlign="center"
							dataSort={true}
							isKey={true}
							hidden={true}
						>
							ID
						</TableHeaderColumn>
						<TableHeaderColumn dataField="Description" dataAlign="left" dataSort={true} dataFormat={selectBox}>
							{this.state.boxesToSelectFromCaption}
						</TableHeaderColumn>
					</BootstrapTable>
				</div>
				<div className="col-md-6">
					<BootstrapTable
						data={this.state.selectedShippingBoxes ? this.state.selectedShippingBoxes : []}
						search={true}
						hover={true}
						height="300"
					>
						<TableHeaderColumn
							width="60"
							dataField="ShippingBoxId"
							dataAlign="center"
							dataSort={true}
							isKey={true}
							hidden={true}
						>
							ID
						</TableHeaderColumn>
						<TableHeaderColumn dataField="Description" dataAlign="left" dataSort={true} dataFormat={deselectBox}>
							{this.state.selectedShippingBoxesCaption}
						</TableHeaderColumn>
					</BootstrapTable>
				</div>
			</div>
		)
	}
}
