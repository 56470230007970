import React from 'react'
import HrefLink from '../ex/HrefLink'
import MailUsLink from '../ex/MailUsLink'

export default class HelpSectionFaq extends React.Component {
	renderQA(qa) {
		return (
			<div className="">
				<h5>
					<i className="label label-primary">Q</i> {qa.q}
				</h5>
				<p style={{ marginBottom: '20px' }}>{qa.a}</p>
			</div>
		)
	}

	render() {
		var qa = [
			{
				q: (
					<strong>
						Is a <i>shipping box</i> equivalent to what Viper refers to as a <i>shipping container</i>?
					</strong>
				),
				a: (
					<span>
						Yes, they are the same. In industry standard terminology, a <i>shipping container</i> generally refers to an{' '}
						<HrefLink to="https://en.wikipedia.org/wiki/Intermodal_container" title="intermodal freight container" />,
						which is not what the Shipping Box Management service is modeling.
					</span>
				),
			},
			{
				q: 'How can I get access to a particular fulfillment location?',
				a: (
					<span>
						Please email the <MailUsLink subject="" />.
					</span>
				),
			},
			{
				q: 'What is the Users tab for?',
				a: 'This is where authorization is managed for users. It is only viewable by those with admin privileges.',
			},
			{
				q: 'How can I receive updates regarding the Shipping Box Management service?',
				a: (
					<span>
						You can add yourself to the <em>Shipping Box Management Service Users</em> distribution list through{' '}
						<HrefLink to="https://cimpress.service-now.com/it/" title="MyIT" />.
					</span>
				),
			},
		]

		return (
			<div>
				<h3>Frequently Asked Questions</h3>
				<br />
				{qa.map(
					function (item) {
						return this.renderQA(item)
					}.bind(this),
				)}
			</div>
		)
	}
}
