'use strict'

const validator = require('../qp-components/qp-validators')
const validateVolume = require('../utils/InteriorVolumeValidator')

// checks whether all the parameters of ShippingBoxParams are valid
module.exports.validateShippingBoxParams = validateShippingBoxParams
function validateShippingBoxParams(params) {
	var validationErrors = []
	// field validations
	params.ExteriorDimensions.forEach(function (dimension) {
		validationErrors = validationErrors.concat(validator.validateLength(dimension, 'ExteriorDimensions'))
	})
	params.InteriorDimensions.forEach(function (dimension) {
		validationErrors = validationErrors.concat(validator.validateLength(dimension, 'InteriorDimensions'))
	})
	params.DimensionsCanIncreaseBy.forEach(function (dimension) {
		validationErrors = validationErrors.concat(validator.validateFlexibleLength(dimension, 'DimensionsCanIncreaseBy'))
	})
	validationErrors = validationErrors.concat(validator.validateWeight(params.Weight, 'Weight'))
	validationErrors = validationErrors.concat(
		validator.validateWeight(params.EstimatedMaxHoldWeight, 'EstimatedMaxHoldWeight'),
	)
	validationErrors = validationErrors.concat(validator.validatePositiveNumber(params.Cost, 'Cost'))
	validationErrors = validationErrors.concat(validator.validateNonEmptyString(params.Description, 'Description'))

	// cross field validations
	const volumeCheck = validateVolume(params, 'Dimensions')
	volumeCheck.forEach(error => validationErrors.push(error.message))
	return validationErrors
}

module.exports.validateShippingBoxTagKey = validateShippingBoxTagKey
function validateShippingBoxTagKey(key) {
	if (/^[a-zA-Z0-9:_-]+$/i.test(key)) {
		return []
	} else {
		return [
			`Invalid tag key ${key}. The tag key can only contain alphanumeric characters, dashes, colons and underscores.`,
		]
	}
}

module.exports.validateViperShippingBoxTagKey = validateViperShippingBoxTagKey
function validateViperShippingBoxTagKey(key, value) {
	const tagActionRowKey = key.trim().toLowerCase()
	if (
		tagActionRowKey === 'viper:closingdeviceminweight' ||
		tagActionRowKey === 'viper:singleitemweight' ||
		tagActionRowKey === 'viper:minaccumweight' ||
		tagActionRowKey === 'viper:maxaccumweight'
	) {
		return validator.validateWeight(value, key)
	} else if (tagActionRowKey === 'viper:shippingcategory') {
		return validateShippingCategory(value)
	} else {
		return []
	}
}

module.exports.validateEligibilityRulesParams = validateEligibilityRulesParams
function validateEligibilityRulesParams(rules, ruleValidator) {
	var validationErrors = []

	rules.forEach(function (rule) {
		validationErrors = validationErrors.concat(ruleValidator(rule.Condition))
		validationErrors = validationErrors.concat(validator.validatePositiveNumber(rule.Index, 'Eligibility Rule Index'))
	})

	var indexNbrSet = new Set(
		rules.map(function (rule) {
			return rule.Index
		}),
	)
	validationErrors = validationErrors.concat(
		validateEqual(rules.length, indexNbrSet.size, 'There are duplicated index number in rules'),
	)
	return validationErrors
}

module.exports.validateEligibilityCondition = validateEligibilityCondition
function validateEligibilityCondition(expression) {
	// TODO:
	// 1) Move these to configuration file
	// 2) Provide even more restrictions for example for the possible values for BinningType
	var validationErrors = disallowSingleQuotes(expression)
	validationErrors = validationErrors.concat(
		validateExpression(expression, ['OrderBrand', 'WorkflowType', 'BinningType'], ['==', '!=', '&&', '||']),
	)
	return validationErrors
}

module.exports.validateBoxRecommendationCondition = validateBoxRecommendationCondition
function validateBoxRecommendationCondition(expression) {
	var validationErrors = disallowSingleQuotes(expression)
	validationErrors = validationErrors.concat(
		validateExpression(expression, ['SkuCode', 'Quantity'], ['==', '!=', '&&', '||', '<', '<=', '>=', '>']),
	)
	return validationErrors
}

module.exports.validateShippingCategory = validateShippingCategory
function validateShippingCategory(shippingCategory) {
	return shippingCategory === 'Parcel' || shippingCategory === 'Flat' || shippingCategory === 'Envelope'
		? []
		: [`Invalid shipping category: ${shippingCategory}. Expected "Parcel", "Flat" or "Envelope"`]
}

function validateTree(x, validIdentifiers, validOperators) {
	if (x.type === 'LogicalExpression') {
		if (validOperators.indexOf(x.operator) < 0) {
			throw new Error('Allowed operators: ' + validOperators.join(', ') + ' Found: ' + x.operator)
		}

		if (x.left.type == 'Literal') {
			throw new Error('Literal is not allowed as part of LogicalExpression! (' + x.left.value + ')')
		}

		if (x.right.type == 'Literal') {
			throw new Error('Literal is not allowed as part of LogicalExpression! (' + x.right.value + ')')
		}

		validateTree(x.left, validIdentifiers, validOperators)
		validateTree(x.right, validIdentifiers, validOperators)
	} else if (x.type === 'BinaryExpression') {
		if (validOperators.indexOf(x.operator) < 0) {
			throw new Error('Allowed operators: ' + validOperators.join(', ') + ' Found: ' + x.operator)
		}

		if (x.left.type == 'BinaryExpression' || x.left.type == 'LogicalExpression') {
			throw new Error(
				'Invalid Expression found: <invalid operand> ' +
					x.operator +
					' ' +
					(x.right.name || x.right.value || 'operand'),
			)
		}
		if (x.right.type == 'BinaryExpression' || x.right.type == 'LogicalExpression') {
			throw new Error(
				'Invalid Expression found: ' +
					(x.right.name || x.right.value || 'operand') +
					' ' +
					x.operator +
					' <invalid operand>',
			)
		}

		validateTree(x.left, validIdentifiers, validOperators)
		validateTree(x.right, validIdentifiers, validOperators)
	} else if (x.type === 'Identifier') {
		if (validIdentifiers.indexOf(x.name) < 0) {
			throw new Error('Allowed identifiers: ' + validIdentifiers.join(', ') + ' Found: ' + x.name)
		}
	} else if (x.type === 'Literal') {
		// Skip literals
	} else {
		throw new Error('Unexpected expression type: ' + x.type)
	}
}

function validateExpression(expression, validIdentifiers, validOperators) {
	var jsep = require('jsep')

	try {
		// Parse the expression (will throw in case of syntax error)
		var tree = jsep(expression)

		// Do not allow single identifiers such as 'WorkflowType'
		if (tree.type == 'Identifier') {
			throw new Error('The condition cannot be single identifier: ' + tree.name)
		}

		// Allow only 'true' or 'false' as single literals
		if (tree.type == 'Literal' && tree.value !== true && tree.value !== false) {
			throw new Error("The condition cannot be single literal other than true or false: '" + tree.value + "'")
		}

		// Extract all identifiers
		validateTree(tree, validIdentifiers, validOperators)
	} catch (err) {
		// Unacceptable expression
		return [err.message]
	}
	return []
}

function disallowSingleQuotes(value) {
	return value.indexOf("'") == -1 ? [] : ['Invalid value. Single quotes " \' " are not allowed']
}

function validateEqual(right, left, error) {
	return right === left ? [] : [error]
}
