'use strict'

import React from 'react'
import PropTypes from 'prop-types'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import update from 'react-addons-update'
import ConfirmBox from './ex/ConfirmBox'
import Tooltip from '@cimpress/react-components/lib/Tooltip'
import Snackbar from '@cimpress/react-components/lib/Snackbar'
import TextFieldWithValidation from './ex/TextFieldWithValidation'
import validator from '../qp-components/qp-validators'
import boxValidator from '../utils/boxValidator'
import quantities from 'js-quantities'
import auth from './auth/auth'

export default class ShippingBoxTags extends React.Component {
	constructor(props) {
		super(props)
		this.analyzeProps(props, false)
	}

	componentWillReceiveProps(props) {
		if (this.state.isTagEditing) {
			return
		}

		this.analyzeProps(props, true)
	}

	analyzeProps(props, useSetState) {
		var newState = {
			shippingBoxId: props.shippingBox ? props.shippingBox.ShippingBoxId : null,
			shippingBoxTags: JSON.parse(JSON.stringify(props.shippingBox ? props.shippingBox.Tags : [])),
			isTagEditing: false,
			tagActionRowKey: null,
			tagActionRowValue: null,
			originalTagActionRowKey: null,
			originalTagActionRowValue: null,
		}

		if (useSetState) {
			this.setState(newState)
		} else {
			this.state = newState
		}
	}

	cancel() {
		this.setState({
			isTagEditing: false,
			tagActionType: null,
			tagActionRowKey: null,
			tagActionRowValue: null,
			originalTagActionRowKey: null,
			originalTagActionRowValue: null,
			tagActionRowReason: null,
		})
	}

	stateToShowSnackBar(message, type = 'danger') {
		return {
			snackBarShow: true,
			snackBarType: type,
			snackBarBody: message,
		}
	}

	saveChanges(actionType) {
		const token = auth.getAccessToken()

		let value = ''
		let type = ''
		let command = ''
		let tagValue = this.state.tagActionRowValue

		const key = this.state.tagActionRowKey.trim()
		const lowerCaseKey = key.toLowerCase()
		if (actionType === 'Add' || actionType === 'Edit') {
			let validationErrors = boxValidator.validateViperShippingBoxTagKey(key, this.state.tagActionRowValue)
			if (validationErrors.length > 0) {
				this.setState(
					Object.assign(
						{},
						{
							isSaving: false,
							confirmBoxShow: false,
						},
						this.stateToShowSnackBar(`Invalid value for tag key ${key}. Error: ${validationErrors}`),
					),
				)
				return
			}
		}

		if (
			lowerCaseKey === 'viper:closingdeviceminweight' ||
			lowerCaseKey === 'viper:singleitemweight' ||
			lowerCaseKey === 'viper:minaccumweight' ||
			lowerCaseKey === 'viper:maxaccumweight'
		) {
			// Viper doesn't accept measurements without spaces between the value and unit as valid
			// e.g. "1lb" is invalid but "1 lb" is valid
			const quantity = quantities(tagValue)
			tagValue = quantity.toString()
			if (tagValue.indexOf('lbs') >= 0) {
				tagValue = tagValue.slice(0, tagValue.length - 1)
			}
		}

		switch (actionType) {
			case 'Add':
				value = tagValue
				type = 'POST'
				command = 'added'
				break
			case 'Edit':
				value = tagValue
				type = 'PUT'
				command = 'updated'
				break
			case 'Delete':
				value = ''
				type = 'DELETE'
				command = 'deleted'
				break
			default:
				this.setState(
					Object.assign(
						{},
						{
							isSaving: false,
							confirmBoxShow: false,
						},
						this.stateToShowSnackBar('Please contact Quoting and Planning regarding action type ' + actionType + '.'),
					),
				)
				return
		}

		this.setState({ isSaving: true })

		var tagData = {
			ShippingBoxId: this.state.shippingBoxId,
			Key: key,
			Value: value || '',
			Reason: this.state.tagActionRowReason,
		}

		this.serverRequest = window.$.ajax({
			type: type,
			url: `${process.env.REACT_APP_SERVICE_URL}/api/v1/shippingBoxTag`,
			timeout: 10000,
			data: JSON.stringify(tagData),
			contentType: 'application/json',
			dataType: 'json',
			headers: {
				Authorization: `Bearer ${token}`,
			},

			success: function (data) {
				this.setState(
					Object.assign(
						{},
						{
							shippingBoxTags: JSON.parse(JSON.stringify(data.Tags)),
							isSaving: false,
							isTagEditing: false,
							tagActionType: null,
							tagActionRowKey: null,
							tagActionRowValue: null,
							originalTagActionRowKey: null,
							originalTagActionRowValue: null,
							tagActionRowReason: null,
							confirmBoxShow: false,
						},
						this.stateToShowSnackBar('Tag "' + tagData.Key + '" ' + command + ' successfully.', 'info'),
					),
				)

				if (this.props.afterUpdated) {
					this.props.afterUpdated(data)
				}
			}.bind(this),

			error: function (error) {
				this.setState(
					Object.assign(
						{},
						{
							isSaving: false,
							confirmBoxShow: false,
						},
						this.stateToShowSnackBar(this.formatErrorMessage(error)),
					),
				)
			}.bind(this),
		})
	}

	formatErrorMessage(error) {
		if (error.responseJSON) {
			return error.responseJSON.results.errors.map(function (each) {
				return each.code + ': ' + each.description + '(' + each.message + ')<br/><br/>'
			})
		} else if (error.responseText) {
			return error.responseText
		}
		return JSON.stringify(error)
	}

	onAddTagBtnClick() {
		this.setState({
			isTagEditing: true,
			tagActionType: 'Add',
			tagActionRowKey: null,
			tagActionRowValue: null,
			originalTagActionRowKey: '',
			originalTagActionRowValue: '',
		})
	}

	onTagKeyClick(row, e) {
		e.preventDefault()
		this.setState({
			isTagEditing: true,
			tagActionType: 'Edit',
			tagActionRowKey: row.Key,
			tagActionRowValue: row.Value ? row.Value : ' ',
			originalTagActionRowKey: row.Key,
			originalTagActionRowValue: row.Value ? row.Value : ' ',
		})
	}

	onDeleteTagBtnClick(tagKey) {
		this.setState({
			confirmBoxShow: true,
			confirmBoxTitle: 'Delete tag',
			confirmBoxBody: 'Are you sure you want to delete the tag named "' + tagKey + '"?',
			confirmBoxOnConfirm: this.saveChanges.bind(this, 'Delete'),
		})
	}

	onSaveTagBtnClick() {
		this.saveChanges(this.state.tagActionType)
	}

	onChangeValue(key, event) {
		var newState = update(this.state, {
			[key]: {
				$set: event.target.value,
			},
		})

		this.setState(newState)
	}

	handleValueChange(key, value) {
		var newState = update(this.state, {
			[key]: {
				$set: value,
			},
		})
		this.setState(newState)
	}

	onHideSnackbar() {
		this.setState({
			snackBarShow: false,
		})
	}

	containsChanges() {
		if (this.state.isTagEditing) {
			return (
				this.state.tagActionRowKey != this.state.originalTagActionRowKey ||
				this.state.tagActionRowValue != this.state.originalTagActionRowValue ||
				this.state.tagActionRowReason
			)
		}
		return false
	}

	onConfirmBoxCancel() {
		this.setState({
			confirmBoxShow: false,
		})
	}

	render() {
		var _this = this

		var tableOptions = {
			sortName: 'Key',
			sortOrder: 'asc',
		}

		function keyFormatter(cell, row) {
			return (
				<a href="#" onClick={_this.onTagKeyClick.bind(_this, row)}>
					{cell}
				</a>
			)
		}

		let readonlyTooltip = null
		if (!this.props.canManage) {
			readonlyTooltip = 'Read-only mode active'
		}

		let saveValidationTooltip = null
		if (!this.state.tagActionRowReason || !this.state.tagActionRowKey) {
			saveValidationTooltip = 'Please enter valid tag key as well as a reason for the change'
		}

		var content = ''
		if (this.state.isTagEditing) {
			content = (
				<div>
					<TextFieldWithValidation
						value={this.state.tagActionRowKey}
						label={this.state.tagActionType != 'Add' ? 'Tag key' : 'Please enter tag key'}
						handleValueChange={this.handleValueChange.bind(this, 'tagActionRowKey')}
						name="tagActionRowKey"
						validator={boxValidator.validateShippingBoxTagKey}
						helpMessage="&nbsp;"
						errorMessage="Enter non-empty key with no whitespaces"
						disabled={!this.props.canManage || this.state.tagActionType !== 'Add'}
						title={readonlyTooltip}
					/>

					<TextFieldWithValidation
						value={this.state.tagActionRowValue}
						required={false}
						label="Please enter tag value"
						handleValueChange={this.handleValueChange.bind(this, 'tagActionRowValue')}
						name="tagActionRowValue"
						helpMessage="&nbsp;"
						disabled={!this.props.canManage || this.state.isSaving}
						title={readonlyTooltip}
					/>

					<TextFieldWithValidation
						value={this.state.tagActionRowReason}
						disabled={!this.props.canManage || this.state.isSaving}
						title={readonlyTooltip}
						label="Reason"
						handleValueChange={this.handleValueChange.bind(this, 'tagActionRowReason')}
						name="tagActionRowReason"
						helpMessage="&nbsp;"
						errorMessage="Please provide a reason for this change."
						validator={validator.validateNonEmptyString}
					/>

					<div>
						<p
							style={{
								display: this.state.isTagEditing && this.state.tagActionType != 'Add' ? 'inline-block' : 'none',
							}}
						>
							<Tooltip
								contents={!this.state.tagActionRowReason ? 'Please enter reason for deletion' : 'Delete the tag'}
							>
								<button
									className="btn btn-danger"
									disabled={!this.props.canManage || this.state.isSaving || !this.state.tagActionRowReason}
									title={readonlyTooltip}
									onClick={
										!this.state.isSaving ? this.onDeleteTagBtnClick.bind(this, this.state.tagActionRowKey) : null
									}
								>
									<i className="fa fa-trash" />
									&nbsp;Delete
								</button>
							</Tooltip>
						</p>
						&nbsp;&nbsp;
						<div className="pull-right">
							<button
								className="btn btn-default"
								disabled={!this.props.canManage || this.state.isSaving}
								onClick={this.cancel.bind(this)}
							>
								<i className="fa fa-ban" />
								&nbsp;Cancel
							</button>
							&nbsp;&nbsp;
							<Tooltip contents={readonlyTooltip || saveValidationTooltip || 'Save'}>
								<button
									className="btn btn-primary"
									disabled={
										!this.props.canManage ||
										this.state.isSaving ||
										!this.state.tagActionRowReason ||
										!this.state.tagActionRowKey
									}
									title={readonlyTooltip}
									onClick={!this.state.isSaving ? this.onSaveTagBtnClick.bind(this) : null}
								>
									<i className="fa fa-floppy-o" aria-hidden="true" />
									&nbsp;Save
								</button>
							</Tooltip>
							&nbsp;&nbsp;
						</div>
					</div>
				</div>
			)
		} else {
			content = (
				<div>
					<div className="btn-group">
						<button
							className="btn btn-primary"
							disabled={!this.props.canManage}
							title={readonlyTooltip}
							onClick={this.onAddTagBtnClick.bind(this)}
						>
							<i className="fa fa-plus" />
							&nbsp;Add
						</button>
					</div>

					<div className="row qp-bs-table-row">
						<div className="col-md-12">
							<BootstrapTable
								data={this.state.shippingBoxTags}
								options={tableOptions}
								hover={true}
								search={true}
								trClassName="active-box"
							>
								<TableHeaderColumn
									dataField="Key"
									width={'300px'}
									columnClassName="tagKeyColumn"
									dataSort={true}
									isKey={true}
									dataFormat={keyFormatter}
								>
									Key
								</TableHeaderColumn>
								<TableHeaderColumn dataField="Value" columnClassName="tagValueColumn">
									Value
								</TableHeaderColumn>
							</BootstrapTable>
						</div>
					</div>
				</div>
			)
		}

		return (
			<div id="tagTab" className="qp-margin-bottom">
				<ConfirmBox
					show={this.state.confirmBoxShow || false}
					title={this.state.confirmBoxTitle}
					body={this.state.confirmBoxBody}
					onCancel={this.onConfirmBoxCancel.bind(this)}
					onConfirm={this.state.confirmBoxOnConfirm ? this.state.confirmBoxOnConfirm : null}
				/>
				<Snackbar
					show={this.state.snackBarShow || false}
					onHideSnackbar={this.onHideSnackbar.bind(this)}
					bsStyle={this.state.snackBarType || 'danger'}
					delay="8000"
				>
					{this.state.snackBarBody}
				</Snackbar>
				{content}
			</div>
		)
	}
}

ShippingBoxTags.propTypes = {
	afterUpdated: PropTypes.func,
	canManage: PropTypes.bool,
}
