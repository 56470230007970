'use strict'

import React from 'react'
import PropTypes from 'prop-types'
import Modal from '@cimpress/react-components/lib/Modal'

export default class ConfirmBox extends React.Component {
	cancel() {
		if (this.props.onCancel) {
			this.props.onCancel()
		}
	}

	confirm() {
		if (this.props.onConfirm) {
			this.props.onConfirm()
		}
	}

	render() {
		var footer = (
			<div>
				&nbsp;
				<div className="pull-right">
					<button className="btn btn-default" onClick={this.cancel.bind(this)}>
						No
					</button>
					&nbsp;
					<button className="btn btn-primary" onClick={this.confirm.bind(this)}>
						Yes
					</button>
				</div>
			</div>
		)

		return (
			<Modal
				show={this.props.show}
				onRequestHide={this.cancel.bind(this)}
				title={this.props.title}
				closeButton="true"
				bsSize="sm"
				footer={footer}
			>
				{this.props.body}
			</Modal>
		)
	}
}

ConfirmBox.propTypes = {
	show: PropTypes.bool.isRequired,
	title: PropTypes.string,
	body: PropTypes.string,
	onCancel: PropTypes.func,
	onConfirm: PropTypes.func,
}
