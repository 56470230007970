'use strict'

import React from 'react'
import PropTypes from 'prop-types'

export default class ProgressBar extends React.Component {
	constructor(props) {
		super(props)
	}

	render() {
		if (!this.props.visible) {
			return <span />
		}

		var btnClass = 'btn btn-primary'
		var progressClass = 'progress-bar progress-bar-success'
		if (this.props.hasError) {
			btnClass = 'btn btn-primary'
			progressClass = 'progress-bar progress-bar-danger'
		}

		var finishButton = ''
		if (this.props.maxValue == this.props.currentValue) {
			finishButton = (
				<button className={btnClass} onClick={this.props.onFinishButtonClick}>
					{this.props.finishButtonCaption}
				</button>
			)
		}

		var value = (this.props.currentValue * 100) / this.props.maxValue
		var valueInPercent = value + '%'

		var i = 0
		return (
			<div className="card">
				<div className="card-block">
					<div className="progress">
						<div
							className={progressClass}
							role="progressbar"
							aria-valuenow={value}
							aria-valuemin="0"
							aria-valuemax="100"
							style={{ width: valueInPercent }}
						>
							<span className="sr-only">{valueInPercent} Complete</span>
						</div>
					</div>
					{this.props.progressDetails.map(function (status) {
						return <p key={i++}>{status}</p>
					})}
					{finishButton}
				</div>
			</div>
		)
	}
}

ProgressBar.propTypes = {
	visible: PropTypes.bool,
	hasError: PropTypes.bool,
	maxValue: PropTypes.number,
	currentValue: PropTypes.number,
	onFinishButtonClick: PropTypes.func,
	finishButtonCaption: PropTypes.string,
	progressDetails: PropTypes.object,
}
